import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Radio, Switch, Button, Input, Tag } from "antd";

import {
  setVisibilidade,
  setAtivo,
  setMostrarResultado,
  setMostrarGabarito,
  resetQuiz,
  setEmailNovo,
  updateEmailsNovos,
  updateEmailsAdicionados,
} from "../../../actions/Quiz.action";

import { API_URL } from "../../../configs/config";

import { Container, Field, InvalidFeedback } from "./styles";

function PublicacaoQuiz({
  idQuiz,
  quizPublicado,
  titulo,
  qtdPorPagina,
  tipo,
  expiracao,
  visibilidade,
  ativo,
  mostrarResultado,
  mostrarGabarito,
  emailsNovos,
  emailsAdicionados,
  emailsAdicionadosOriginais,
  questoes,
  questoesOriginais,
}) {
  const dispatch = useDispatch();

  const [inputEmailNovo, setInputEmailNovo] = useState("");
  const [inputEmailNovoError, setInputEmailNovoError] = useState(false);

  const { accessToken } = useSelector((state) => state.login);

  useEffect(() => {
    setInputEmailNovoError(false);
  }, [inputEmailNovo]);

  async function publicarQuestao(questao) {
    try {
      var respostas = [];
      if (tipo !== "certo ou errado") {
        respostas.push(questao.respostas[0].texto);
        respostas.push(questao.respostas[1].texto);
        respostas.push(questao.respostas[2].texto);
        respostas.push(questao.respostas[3].texto);
        if (tipo === "múltipla escolha 5") {
          respostas.push(questao.respostas[4].texto);
        }
      } else {
        respostas.push("Certo");
        respostas.push("Errado");
      }
      let opts = {
        respostas: respostas,
        opcaoCorreta: questao.opcaoCorreta,
        feedback: questao.feedback,
        enunciado: questao.enunciado,
      };
      let headers = new Headers({
        Authorization: `Basic ${accessToken}`,
        "Content-Type": "application/json",
      });
      const body = JSON.stringify(opts);
      const _questaoResponse = await fetch(
        `${API_URL}/simulados/${idQuiz}/questao`,
        {
          method: "post",
          headers,
          body,
        }
      );
      await _questaoResponse.json();
    } catch (err) {
      return false;
    } finally {
      return true;
    }
  }

  async function editarQuestao(questao) {
    try {
      var respostas = [];
      if (tipo !== "certo ou errado") {
        respostas.push(questao.respostas[0].texto);
        respostas.push(questao.respostas[1].texto);
        respostas.push(questao.respostas[2].texto);
        respostas.push(questao.respostas[3].texto);
        if (tipo === "múltipla escolha 5") {
          respostas.push(questao.respostas[4].texto);
        }
      } else {
        respostas.push("Certo");
        respostas.push("Errado");
      }
      let opts = {
        respostas: respostas,
        opcaoCorreta: questao.opcaoCorreta,
        feedback: questao.feedback,
        enunciado: questao.enunciado,
      };
      let headers = new Headers({
        Authorization: `Basic ${accessToken}`,
        "Content-Type": "application/json",
      });
      const body = JSON.stringify(opts);
      const _questaoResponse = await fetch(
        `${API_URL}/simulados/${idQuiz}/questao/${questao.id}`,
        {
          method: "put",
          headers,
          body,
        }
      );
      await _questaoResponse.json();
    } catch (err) {
      return false;
    } finally {
      return true;
    }
  }

  async function removerQuestao(questao) {
    try {
      let headers = new Headers({
        Authorization: `Basic ${accessToken}`,
        "Content-Type": "application/json",
      });
      const _questoesResponse = await fetch(
        `${API_URL}/simulados/${idQuiz}/questao/${questao.id}`,
        {
          method: "delete",
          headers,
        }
      );
      await _questoesResponse.json();
    } catch (err) {
      return false;
    } finally {
      return true;
    }
  }

  async function removerEmailAdicionado(ea) {
    try {
      let headers = new Headers({
        Authorization: `Basic ${accessToken}`,
        "Content-Type": "application/json",
      });
      const _emailAdiconadoResponse = await fetch(
        `${API_URL}/simulados/${idQuiz}/removerusuario/${ea.email}`,
        {
          method: "delete",
          headers,
        }
      );
      await _emailAdiconadoResponse.json();
    } catch (err) {
      return false;
    } finally {
      return true;
    }
  }

  async function editar() {
    try {
      let headers = new Headers({
        Authorization: `Basic ${accessToken}`,
        "Content-Type": "application/json",
      });
      const opts = {
        titulo,
        qtdPorPagina,
        tipo,
        expiracao,
        visibilidade,
        ativo: ativo ? "TRUE" : "FALSE",
        mostrarresultado: mostrarResultado ? "TRUE" : "FALSE",
        mostrargabarito: mostrarGabarito ? "TRUE" : "FALSE",
        emailsNovos,
      };

      const body = JSON.stringify(opts);
      const _quizResponse = await fetch(`${API_URL}/simulados/${idQuiz}`, {
        method: "put",
        headers,
        body,
      });
      const quizResponse = await _quizResponse.json();

      questoesOriginais.map((qo) => {
        const exists = questoes.find((q) => qo.id === q.id);
        if (!exists) {
          removerQuestao(qo);
        }
        return true;
      });

      questoes.map((questao) => {
        if (questao.id) {
          editarQuestao(questao);
        } else {
          publicarQuestao(questao);
        }
        return true;
      });

      emailsAdicionadosOriginais.map((eao) => {
        const eExists = emailsAdicionados.find((ea) => eao.email === ea.email);
        if (!eExists) {
          removerEmailAdicionado(eao);
        }
        return true;
      });

      dispatch(resetQuiz());
      quizPublicado(quizResponse);
    } catch (err) {
    } finally {
    }
  }

  function adicionarEmailNovo() {
    if (emailsNovos && emailsNovos.indexOf(inputEmailNovo) !== -1) {
      setInputEmailNovoError("O e-mail já está cadastrado");
      return false;
    }

    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regex.test(inputEmailNovo)) {
      dispatch(setEmailNovo(inputEmailNovo));
      setInputEmailNovo("");
    } else {
      setInputEmailNovoError("Informe um e-mail válido");
    }
  }

  function removeTag(e, k) {
    e.preventDefault();
    const emn = emailsNovos.filter((em, key) => key !== k);
    dispatch(updateEmailsNovos(emn));
  }

  function handleRemoverEmailAdicionado(e, k) {
    e.preventDefault();
    const ema = emailsAdicionados.filter((em, key) => key !== k);
    dispatch(updateEmailsAdicionados(ema));
  }

  return (
    <Container>
      <Field>
        <div>
          <span>Visibilidades</span>
        </div>
        <Radio.Group
          value={visibilidade}
          options={[
            { label: "Particular", value: "particular" },
            { label: "Privada", value: "privada" },
            { label: "Pública", value: "pública" },
          ]}
          onChange={({ target }) => dispatch(setVisibilidade(target.value))}
          optionType="button"
          buttonStyle="solid"
        />
        {visibilidade === "particular" && (
          <p>
            <small>Apenas eu tenho acesso</small>
          </p>
        )}
        {visibilidade === "privada" && (
          <p>
            <small>Apenas quem for adicionado terá acesso</small>
          </p>
        )}
        {visibilidade === "pública" && (
          <p>
            <small>Qualquer pessoa pode acessar</small>
          </p>
        )}
      </Field>
      <Field>
        <div>
          <span>E-mails adicionados</span>
        </div>
        <div className="mb-2">
          {emailsAdicionados &&
            emailsAdicionados.map((e, key) => (
              <Tag
                key={key}
                closable
                onClose={(e) => handleRemoverEmailAdicionado(e, key)}
              >
                {e.email}
              </Tag>
            ))}
        </div>
      </Field>
      <Field>
        <div>
          <span>E-mails novos</span>
        </div>
        <div className="mb-2">
          {emailsNovos &&
            emailsNovos.map((e, key) => (
              <Tag key={key} closable onClose={(e) => removeTag(e, key)}>
                {e}
              </Tag>
            ))}
        </div>
        <div style={{ display: "flex" }}>
          <Input
            name="emailNovo"
            className="mr-1"
            type="email"
            value={inputEmailNovo}
            onChange={(ev) => setInputEmailNovo(ev.target.value)}
          />
          <Button
            onClick={() => {
              adicionarEmailNovo();
            }}
          >
            Adicionar
          </Button>
        </div>
        {inputEmailNovoError && (
          <InvalidFeedback>{inputEmailNovoError}</InvalidFeedback>
        )}
      </Field>
      <Field>
        <div>
          <span>Ativar/Desativar quiz</span>
        </div>
        <Switch
          checked={ativo}
          onChange={(value) => dispatch(setAtivo(value))}
          checkedChildren={<span style={{ fontSize: 14 }}>Ativo</span>}
          unCheckedChildren={<span style={{ fontSize: 14 }}>Inativo</span>}
        />
      </Field>
      <Field>
        <div>
          <span>
            Mostrar o resultado (quantidade de acertos) ao concluir o quiz?
          </span>
        </div>
        <Switch
          checked={mostrarResultado}
          onChange={(value) => dispatch(setMostrarResultado(value))}
          checkedChildren={<span style={{ fontSize: 14 }}>Sim</span>}
          unCheckedChildren={<span style={{ fontSize: 14 }}>Não</span>}
        />
      </Field>
      <Field>
        <div>
          <span>Mostrar o gabarito ao concluir o quiz?</span>
        </div>
        <Switch
          checked={mostrarGabarito}
          onChange={(value) => dispatch(setMostrarGabarito(value))}
          checkedChildren={<span style={{ fontSize: 14 }}>Sim</span>}
          unCheckedChildren={<span style={{ fontSize: 14 }}>Não</span>}
        />
      </Field>
      <div className="mb-3">
        <Button type="primary" onClick={() => editar()}>
          Publicar alterações
        </Button>
      </div>
    </Container>
  );
}

export default PublicacaoQuiz;
