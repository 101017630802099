import React from 'react'
import style from './style.module.css'
import { Row , Col } from 'antd'
import Logo from '../Logo'
import { Link } from 'react-router-dom'
import { MailOutlined } from '@ant-design/icons';
import { faTelegram, faYoutube, faFacebookSquare, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { INSTAGRAM_URL, TELEGRAM_URL, FACEBOOK_URL, YOUTUBE_URL} from '../../configs/config'

export default () => {

  return (
    <div className={style.footer}>
      <div className="container">

        <Row className="sm-text-center" gutter={[48, 48]}>
          <Col style={{margin: '4px auto'}} sm={{ span: 24 }} md={{ span: 12 }} >
            <div style={{paddingTop: 5}}>
              <Logo type="white" size="small" />
            </div>
            <br />
            <p>Crie simulados para seus alunos e seguidores e responda questões de concursos públicos.</p>
          </Col>
          <Col style={{margin: '4px auto'}} sm={{ span: 24 }} md={{ span: 12 }} >
          <p style={{fontSize:"1.5em"}}>Contato:</p>
            <p><MailOutlined style={{marginRight: 8}} /><Link style={{color: 'white'}} to={"/contato"}>contato@cadedireito.com.br</Link></p>
            <div>
              <a href={INSTAGRAM_URL} aria-label="instagram" id="link-footer-instagram" name="link-footer-instagram" rel="noopener noreferrer" target="_BLANK">
                <FontAwesomeIcon icon={faInstagram} className={style.socialIcon} />
              </a>
              <a href={TELEGRAM_URL} aria-label="telegram" id="link-footer-telegram" name="link-footer-telegram" rel="noopener noreferrer" target="_BLANK">
                <FontAwesomeIcon icon={faTelegram} className={style.socialIcon} />
              </a>
              <a href={YOUTUBE_URL} aria-label="youtube" id="link-footer-youtube" name="link-footer-youtube" rel="noopener noreferrer" target="_BLANK">
                <FontAwesomeIcon icon={faYoutube} className={style.socialIcon} />
              </a>
              <a href={FACEBOOK_URL} aria-label="facebook" id="link-footer-facebook" name="link-footer-facebook" rel="noopener noreferrer" target="_BLANK">
                <FontAwesomeIcon icon={faFacebookSquare} className={style.socialIcon} />
              </a>
            </div>
          </Col>
        </Row>

      </div>
    </div>
  )
}