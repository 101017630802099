import React, { useState } from "react";
import { Input, Button, Alert } from "antd";
import { API_URL } from "../../configs/config";
import { useSelector } from "react-redux";
import swal from "sweetalert";

const { TextArea } = Input;

export default function ReportarErro({ idQuestao }) {
  var [mensagem, setMensagem] = useState("");
  var [enviando, setEnviando] = useState(false);
  var login = useSelector((state) => state.login);

  function onSubmit() {
    setEnviando(true);

    let headers = new Headers({
      Authorization: `Basic ${login.accessToken}`,
      "Content-Type": "application/json",
    });
    const body = JSON.stringify({ mensagem: mensagem });
    fetch(`${API_URL}/questao/${idQuestao}/reportar`, {
      method: "post",
      headers,
      body,
    })
      .then((r) => {
        return r.json();
      })
      .then((r) => {
        swal("Erro reportado com sucesso!", "", "success");
        setMensagem("");
        setEnviando(false);
      })
      .catch((e) => {
        swal("Falha ao tentar reportar o erro!", "", "error");
        setMensagem("");
        setEnviando(false);
      });
  }

  return (
    <div>
      <TextArea
        rows={4}
        value={mensagem}
        style={{ marginBottom: 16 }}
        disabled={!login.accessToken}
        onChange={(e) => setMensagem(e.target.value)}
      />
      {login.accessToken ? (
        <>
          {!enviando ? (
            <Button type="primary" onClick={() => onSubmit()}>
              Reportar erro
            </Button>
          ) : (
            <Button type="primary" disabled>
              Enviando
            </Button>
          )}
        </>
      ) : (
        <Alert
          message="Faça o login para poder reportar o erro."
          type="info"
          showIcon
        />
      )}
    </div>
  );
}
