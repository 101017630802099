import React from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";

export default function Resultados({ resultados }) {
  console.log(resultados);

  // pesquisar por artigos, etc.

  /*return (
    <Row>
      {resultados && (
        <Box>
          <span className="mr-4">Constituição Federal</span>
          <Badge
            count={resultados.length}
            style={{ backgroundColor: "#108ee9" }}
          />
        </Box>
      )}
    </Row>
  );*/

  return (
    <Row gutter={[48, 48]}>
      {resultados &&
        resultados.map((r) => {
          let text = `${r.titulo} - ${r.texto}`;
          text = text.length <= 100 ? text : `${text.substr(0, 97)}...`;
          return (
            <Col xl={6} lg={8} md={12} sm={24}>
              <Link to={`/legislacao/federal/${r.url}`}>{text}</Link>
            </Col>
          );
        })}
    </Row>
  );
}
