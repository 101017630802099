import styled from "styled-components";

export const Container = styled.div`
  p {
    font-size: 1.2em;
    color: #010101;
  }
`;

export const BlueHR = styled.div`
  width: 64px;
  height: 4px;
  background-color: var(--primary2);
  margin: 0px auto 32px;
`;

export const Title = styled.h1`
  color: var(--primary);
`;
