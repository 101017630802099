import styled from "styled-components";
import { Col } from "antd";

export const Container = styled.div`
  background: var(--light-gray);
  border-top: 1px solid #f0eef0;
  border-bottom: 1px solid #f0eef0;
`;

export const ButtonSubmit = styled(Col)`
  align-self: center;
`;

export const Box = styled(Col)`
  background-color: var(--light-gray);
  padding: 12px 24px;
`;
