import React, { Component } from 'react'
import { Col, Row, Skeleton, Empty, Collapse } from 'antd';
import style from './style.module.css'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getBancas } from '../../actions/Bancas.action'
import { Link } from 'react-router-dom'
import { LinkOutlined } from '@ant-design/icons'
import BarraHistorico from '../../components/BarraHistorico'
import { withRouter } from "react-router"
import RenderHelmet from '../../components/RenderHelmet'

import ReactGa from 'react-ga'
import { GA_TRACKING_ID } from "../../configs/config";

const { Panel } = Collapse

class Bancas extends Component {

  componentDidMount = () => {
    ReactGa.initialize(GA_TRACKING_ID);
    ReactGa.pageview("/bancas");

    window.scrollTo(0, 0)
    this.props.getBancas()
  }

  renderLoading = () => {
    return (
      <>
        <Col span={6}>
          <Skeleton active paragraph={{ rows: 4 }}></Skeleton>
        </Col>
        <Col span={6}>
          <Skeleton active paragraph={{ rows: 4 }}></Skeleton>
        </Col>
        <Col span={6}>
          <Skeleton active paragraph={{ rows: 4 }}></Skeleton>
        </Col>
        <Col span={6}>
          <Skeleton active paragraph={{ rows: 4 }}></Skeleton>
        </Col>
        <Col span={6}>
          <Skeleton active paragraph={{ rows: 4 }}></Skeleton>
        </Col>
        <Col span={6}>
          <Skeleton active paragraph={{ rows: 4 }}></Skeleton>
        </Col>
      </>
    )
  }

  renderBanca = (banca) => {
    var total =  0;
    return (
      <Col key={banca.id} style={{ marginBottom: 48, width: '100%' }} sm={{ span: 24 }} md={{ span: 12 }}>
        <div className={style.boxBanca}>
          <h3 style={{lineHeight: '1em'}}>{banca.nome}</h3>
          <Collapse bordered={false} className={style.collapse}>
            <Panel className={style.panel} header={<p className={style.panelHeader}>Concursos:</p>}>
              {
                banca.concursos.map(concurso => {
                  total += concurso.questoes.length
                  return <p key={concurso.id} className={style.collapseContent}><Link to={`/questoes?concursos[]=${concurso.id}`}><LinkOutlined /> {concurso.nome} - {concurso.ano}</Link></p>
                })
              }
            </Panel>
          </Collapse>

          <p className="no-margin">
            <span className="strong no-margin">Total de: </span>
            <Link to={`/questoes?bancas[]=${banca.id}`}><LinkOutlined /> {total} questões</Link>
          </p>

        </div>
      </Col>
    )
  }

  renderBancas = (bancas) => {
    return bancas.map(banca => {
      return this.renderBanca(banca)
    })
  }

  render() {
    const { loadBancas, bancas } = this.props.bancas

    return (
      <div className="container">
        <RenderHelmet title={'Cade Direito: Bancas'} description={'Lista de Bancas de concursos'}/>
        <BarraHistorico title={'Bancas'} match={ this.props.match }/>
        <Row gutter={[24, 24]}>
          {
            bancas
              ? this.renderBancas(bancas)
              : (
                !loadBancas
                  ? <Empty description="Não há órgãos' disponíveis" style={{ margin: '64px 0 128px 0' }} />
                  : <Skeleton active paragraph={{ rows: 12 }}></Skeleton>
                )
          }
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state =>
  ({
    bancas: state.bancas
  })

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getBancas }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Bancas))