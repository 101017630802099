import { API_URL } from "../configs/config";

export const getQuestao = (id) => {
  return (dispatch) => {
    dispatch({ type: "GET_QUESTAO_REQUEST" });
    fetch(`${API_URL}/questao/${id}`)
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        return dispatch({
          type: "GET_QUESTAO_SUCCESS",
          payload: response,
        });
      })
      .catch((error) =>
        dispatch({
          type: "GET_QUESTAO_FAILURE",
          payload: error,
        })
      );
  };
};

export const getQuestoes = (page = 1, params = null, accessToken = null) => {
  var body = "";

  if (page) {
    body = `?page=${page}&size=5`;
  }
  if (params) {
    body += params;
  }

  let headers;

  if (accessToken) {
    headers = new Headers({
      Authorization: `Basic ${accessToken}`,
      "Content-Type": "application/json",
    });
  } else {
    headers = new Headers({
      "Content-Type": "application/json",
    });
  }

  return (dispatch) => {
    dispatch({ type: "GET_QUESTOES_REQUEST" });
    fetch(`${API_URL}/questao${body}`, {
      method: "get",
      headers,
    })
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        return dispatch({
          type: "GET_QUESTOES_SUCCESS",
          payload: response,
        });
      })
      .catch((error) =>
        dispatch({
          type: "GET_QUESTOES_FAILURE",
          payload: error,
        })
      );
  };
};
