import styled from "styled-components";
import { Link } from "react-router-dom";

import bg1md from "../../images/backgrounds/bg3_md.webp";
import bg1sm from "../../images/backgrounds/bg3_sm.webp";
import bg1xs from "../../images/backgrounds/bg3_xs.webp";

import bg2md from "../../images/backgrounds/bg2_md.webp";
import bg2sm from "../../images/backgrounds/bg2_sm.webp";
import bg2xs from "../../images/backgrounds/bg2_xs.webp";

export const Container = styled.div`
  .info-title {
    font-size: 1.5em;
    margin-bottom: 0;
  }
  .chamada-subtitle {
    color: white;
    text-align: center;
    font-size: 2em;
    font-family: "Roboto Condensed", sans-serif;
  }
  .info-subtitle {
    color: black;
    text-align: center;
    font-size: 2em;
    margin-bottom: 0;
    font-family: "Roboto Condensed", sans-serif;
  }
`;

export const BackgroundImage = styled.div`
  width: 100vw;
  height: calc(100vh - 100px);
  background-size: cover;
  position: fixed;
  z-index: -1;
  background-position: 30% 0%;
  &.bg1 {
    background-image: url(${bg1xs});
  }
  &.bg2 {
    background-image: url(${bg2xs});
  }
  @media (min-width: 768px) {
    &.bg1 {
      background-image: url(${bg1sm});
    }
    &.bg2 {
      background-image: url(${bg2sm});
    }
  }
  @media (min-width: 992px) {
    &.bg1 {
      background-image: url(${bg1md});
    }
    &.bg2 {
      background-image: url(${bg2md});
    }
  }
`;

export const Chamada = styled.div`
  background-color: #4d87e377;

  > div {
    width: 100%;
    height: calc(100vh - 170px);
    margin: 0 auto;
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  > div h1 {
    color: white;
    font-size: 3em;
    width: 100%;
    display: block;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.4em;
  }
  > div h3 {
    color: white;
    width: 100%;
    text-align: center;
  }

  @media (min-width: 768px) {
    > div {
      width: 750px;
      height: calc(100vh - 170px);
    }
  }
  @media (min-width: 992px) {
    > div {
      width: 970px;
      height: calc(100vh -170px);
    }
  }
  @media (min-width: 1200px) {
    > div {
      width: 1170px;
      height: calc(100vh - 170px);
    }
  }
`;

export const BtnComece = styled(Link)`
  height: auto;
  padding: 8px 48px;
  background-color: var(--primary2);
  color: white;
  border-radius: 50px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: bold;
  font-size: 2em;
  text-transform: uppercase;
  margin: 16px;
  transition: ease-in-out all 0.3s;
  :hover {
    color: white;
    background-color: var(--primary3);
  }
`;

export const BtnCriarConta = styled(Link)`
  height: auto;
  padding: 12px 64px;
  background-color: var(--primary2);
  color: white;
  border-radius: 50px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: bold;
  font-size: 3em;
  text-transform: uppercase;
  margin: 24px;
  transition: ease-in-out all 0.3s;
  :hover {
    background-color: var(--primary3) !important;
    color: white;
  }
  @media (max-width: 768px) {
    display: block;
    font-size: 1.5em;
    padding: 12px;
  }
`;

export const Info = styled.div`
  background-color: white;
  padding: 96px 12px;
  text-align: center;
  .icon {
    fontsize: 1.4em !important;
  }
`;

export const Conclusao = styled.div`
  background-color: var(--light-gray);
  overflow: hidden;
`;

export const BoxTitulo = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    justify-content: center;
    display: block;
    text-align: center;
  }
  > h2 {
    margin: 0 12px;
  }
`;

export const Social = styled.div`
  background-color: white;
  overflow: hidden;
  .socialDiv {
    margin: 72px 0;
    font-size: 6em;
  }
  .socialDiv span {
    vertical-align: top;
  }
  .socialDiv .socialIcon {
    width: 90px;
    margin: 8px;
  }
  .socialIcon {
    margin: 16px 24px;
  }
  #instagram {
    margin-bottom: 36px;
  }
  #youtube {
    color: var(--youtube);
  }
  #facebook {
    color: var(--facebook);
  }
`;

export const Devices = styled.div`
  background-color: var(--primary3);
  padding: 96px 12px;
  overflow: hidden;

  img {
    max-width: 100%;
  }

  h2,
  h3 {
    color: var(--semi-dark-primary);
  }
`;

export const ContainerDevices = styled.div`
  .img {
    max-width: 100%;
  }
`;
