import React, { useEffect, useState } from "react";
import { API_URL } from "../../../../configs/config";
import { Table, Button } from "antd";
import moment from "moment";
import ModalGabarito from "../modalGabarito";

function SimuladosFinalizados({ login, simulado }) {
  const columns = [
    {
      title: "Nome",
      dataIndex: "usuario",
      key: "usuario",
      render: (usuario, finalizado) => {
        return <>{usuario ? usuario.nome : finalizado.nome}</>;
      },
    },
    {
      title: "Outra Identificação",
      dataIndex: "usuario",
      key: "usuario",
      render: (usuario, finalizado) => {
        return <>{usuario ? usuario.email : finalizado.outraIdentificacao}</>;
      },
    },
    {
      title: "Data",
      dataIndex: "created_at",
      key: "created_at",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        moment(a.created_at).format("X") - moment(b.created_at).format("X"),
      render: (created_at) => moment(created_at).fromNow(),
    },
    {
      title: "Acertos",
      dataIndex: "",
      key: "",
      render: (finalizado) => {
        const matriz = JSON.parse(finalizado.matriz);
        var acertos = 0;
        simulado.simulado_questoes.map((questao, key) => {
          if (matriz[key + 1] === questao.opcaoCorreta) {
            acertos++;
          }
          return true;
        });
        return (
          <>
            {acertos} / {simulado.simulado_questoes.length} (
            {((acertos / simulado.simulado_questoes.length) * 100).toFixed(2)}%)
          </>
        );
      },
    },
    {
      title: "Ações",
      dataIndex: "",
      key: "",
      render: (a) => {
        return (
          <Button
            size="small"
            onClick={() => {
              setShowModalGabarito(true);
              setGabarito(a);
            }}
          >
            Ver gabarito
          </Button>
        );
      },
    },
  ];

  const [showModalGabarito, setShowModalGabarito] = useState(false);
  const [gabarito, setGabarito] = useState(null);
  const [finalizados, setFinalizados] = useState(null);
  const [loading, setLoading] = useState(false);

  /*useEffect(
    function get() {
      getFinalizados();
    },
    [getFinalizados]
  );*/

  useEffect(() => {
    async function getFinalizados() {
      setLoading(true);
      let headers = new Headers({
        Authorization: `Basic ${login.accessToken}`,
        "Content-Type": "application/json",
      });
      const _finalizadosResponse = await fetch(
        `${API_URL}/simuladosfinalizados/${simulado.id}`,
        {
          method: "get",
          headers,
        }
      );
      const finalizadosResponse = await _finalizadosResponse.json();

      setFinalizados(finalizadosResponse.data);
      setLoading(false);
    }
    getFinalizados();
  }, [login, simulado]);

  return (
    <>
      {!loading && finalizados ? (
        <>
          <Table
            rowKey={"id"}
            columns={columns}
            dataSource={finalizados}
            pagination={false}
          />
          {!showModalGabarito || (
            <ModalGabarito
              onOk={(success) => {
                setShowModalGabarito(false);
              }}
              gabarito={gabarito}
              simulado={simulado}
              onCancel={() => setShowModalGabarito(false)}
            />
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default SimuladosFinalizados;
