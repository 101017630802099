import React, {useState} from 'react'

import { SearchOutlined } from '@ant-design/icons'
import styles from './style.module.css'

export default () => {
  const [ active, setActive ] = useState(false);
  return (
    <div className={styles.search}>
      <input
        id="searchInput"
        name="searchInput"
        className={[styles.searchInput, active ? styles.active : ''].join(' ')}
        type="text"
        placeholder="Localizar"
        onBlur={() => setActive(false)}
      />
      <label htmlFor="searchInput">
        <SearchOutlined
          onClick={() => setActive(!active)}
        />
      </label>
    </div>
  )
}