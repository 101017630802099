import React from "react";
import { useDispatch } from "react-redux";
import { Select, Input, Tooltip, DatePicker } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import moment from "moment";

import {
  setTitulo,
  setQtdPorPagina,
  setTipo,
  setExpiracao,
} from "../../../actions/Quiz.action";
import { Container, Field, StyledSelect } from "./styles";

const { Option } = Select;

export default function DadosQuiz({ titulo, qtdPorPagina, tipo, expiracao }) {
  const dispatch = useDispatch();

  const acao = "criação";

  // function onFinish() {}

  return (
    <Container>
      <Field>
        <div>
          <span className="required">Título</span>
        </div>
        <div>
          <Input
            name="titulo"
            value={titulo}
            onChange={(ev) => dispatch(setTitulo(acao, ev.target.value))}
          />
        </div>
      </Field>
      <Field>
        <div>
          <span>Quantidade de questões por página</span>
        </div>
        <div>
          <StyledSelect
            allowClear
            value={qtdPorPagina}
            name="questoesporpagina"
            placeholder="Selecione a quantidade de questões por página"
            onChange={(ev) => dispatch(setQtdPorPagina(acao, ev))}
          >
            <Option value={"5"} key={5}>
              5
            </Option>
            <Option value={"10"} key={10}>
              10
            </Option>
            <Option value={"20"} key={20} selected>
              20
            </Option>
          </StyledSelect>
        </div>
      </Field>
      <Field>
        <div>
          <span>Tipo</span>
        </div>
        <div>
          <StyledSelect
            allowClear
            value={tipo}
            name="tipo"
            placeholder="Selecione um tipo"
            onChange={(ev) => dispatch(setTipo(acao, ev))}
          >
            <Option value={"múltipla escolha 5"} key={1}>
              Múltipla escolha 5
            </Option>
            <Option value={"múltipla escolha 4"} key={2}>
              Múltipla escolha 4
            </Option>
            <Option value={"certo ou errado"} key={3}>
              Certo ou errado
            </Option>
          </StyledSelect>
        </div>
      </Field>
      <Field>
        <div>
          <span>
            Expiração{" "}
            <Tooltip title="Deixe em branco se não houver data de expiração">
              <QuestionCircleOutlined
                style={{ marginLeft: 8, cursor: "pointer" }}
              />
            </Tooltip>
          </span>
        </div>
        <div>
          <DatePicker
            showTime
            value={expiracao ? moment(expiracao) : null}
            placeholder="Selecione a data e hora"
            format="DD-MM-YYYY HH:mm:ss"
            disabledDate={(m) => m.isBefore()}
            onChange={(value) =>
              dispatch(setExpiracao(acao, value.format("Y-M-D H:m:s")))
            }
          />
        </div>
      </Field>
    </Container>
  );
}
