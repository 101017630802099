import React, { Component } from "react";
import style from "./style.module.css";
import { Form, Input, Button, Col, Row, Result } from "antd";
import { MailTwoTone } from "@ant-design/icons";
import Recaptcha from "react-google-invisible-recaptcha";
import {
  RECAPTCHA_ID /*, RECAPTCHA_ID_LOCALHOST*/,
} from "../../configs/config";

import ReactGa from "react-ga";
import { GA_TRACKING_ID } from "../../configs/config";

export default class Contato extends Component {
  componentDidMount = () => {
    ReactGa.initialize(GA_TRACKING_ID);
    ReactGa.pageview("/contato");
    window.scrollTo(0, 0);
  };

  state = {
    loading: false,
    status: "",
  };

  recaptcha = null;
  values = [];

  onFinish = (values) => {
    this.values = values;
    this.recaptcha.execute();
  };

  onResolved = () => {
    const opts = {
      nome: this.values.nome,
      email: this.values.email,
      mensagem: this.values.mensagem,
      //recaptchaKey: this.recaptcha.getResponse()
    };
    this.callbackContato(opts);
  };

  callbackContato = (opts) => {
    let headers = new Headers({
      "Content-Type": "application/json",
    });
    const body = JSON.stringify(opts);
    this.setState({ loading: true });
    fetch(`https://api.cadedireito.com.br/v1/contato`, {
      method: "post",
      headers,
      body,
    })
      .then((r) => {
        return r.json();
      })
      .then((r) => {
        if (r === "sucesso") {
          this.setState({ loading: false, status: "sucesso" });
        } else {
          this.setState({ loading: false, status: "erro", contatoError: true });
        }
      })
      .catch((e) => {
        this.setState({ loading: false, status: "erro", contatoError: true });
      });
  };

  renderSucesso() {
    return (
      <Result
        icon={<MailTwoTone />}
        style={{ margin: "96px 0" }}
        title="Mensagem enviada com sucesso!"
        subTitle="Em breve retornaremos o seu contato."
      />
    );
  }

  render() {
    const { status } = this.state;

    return (
      <>
        {status === "" ? (
          <div style={{ margin: "48px auto" }} className="container">
            <Row>
              <Col sm={{ span: 24 }} md={{ span: 12 }}>
                <div style={{ textAlign: "center", padding: "0px 48px" }}>
                  <h1>Envie-nos uma mensagem!</h1>
                  <p>
                    Caso tem alguma sugestão, reclamação ou deseje nos falar
                    algo, preencha o formulário e nos envie uma mensagem
                  </p>
                  <p>
                    Ficaremos felizes em dar o retorno assim que for possível.
                  </p>
                </div>
              </Col>
              <Col
                sm={{ span: 24 }}
                md={{ span: 12 }}
                style={{ width: "100%" }}
              >
                <Form
                  className={style.formContato}
                  name="register"
                  onFinish={this.onFinish}
                  scrollToFirstError
                >
                  <span>Nome</span>
                  <Form.Item
                    name="nome"
                    rules={[
                      {
                        required: true,
                        message: "Por favor, informe seu nome completo!",
                        whitespace: true,
                      },
                      {
                        min: 5,
                        message: "O nome deve possui 5 caracteres ou mais!",
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input placeholder="Nome completo" />
                  </Form.Item>

                  <span>E-mail</span>
                  <Form.Item
                    name="email"
                    hasFeedback
                    rules={[
                      {
                        type: "email",
                        message: "O E-mail informado não é válido!",
                      },
                      {
                        required: true,
                        message: "Por favor, informe o seu E-mail!",
                      },
                    ]}
                  >
                    <Input placeholder="Informe o e-mail" />
                  </Form.Item>

                  <span>Mensagem</span>
                  <Form.Item
                    name="mensagem"
                    rules={[
                      {
                        required: true,
                        message: "Por favor, informe a sua senha!",
                      },
                      {
                        min: 5,
                        message: "A senha deve possui 5 caracteres ou mais!",
                        whitespace: true,
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.TextArea style={{ minHeight: 192 }} />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      style={{ width: "100%" }}
                      type="primary"
                      htmlType="submit"
                      size="large"
                      disabled={this.state.loading}
                    >
                      {!this.state.loading ? "Enviar mensagem" : "Enviando..."}
                    </Button>
                  </Form.Item>

                  <Recaptcha
                    ref={(ref) => (this.recaptcha = ref)}
                    sitekey={RECAPTCHA_ID}
                    onResolved={this.onResolved}
                  />
                </Form>
              </Col>
            </Row>
          </div>
        ) : (
          this.renderSucesso(status)
        )}
      </>
    );
  }
}
