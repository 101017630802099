import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { getComentarios } from "../../actions/Comentarios.action";
import { connect } from "react-redux";
import style from "./style.module.css";
import { Row, Col, Button, Alert, Collapse, Form } from "antd";
import {
  ScissorOutlined,
  CalendarOutlined,
  CaretRightOutlined,
  FileTextOutlined,
  CheckOutlined,
  LinkOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import Comentario from "../Comentario";
import MenuQuestao from "../MenuQuestao";
import { API_URL } from "../../configs/config";
import { FormInstance } from "antd/lib/form";
import { Link } from "react-router-dom";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import swal from "sweetalert";

const { Panel } = Collapse;

class Questao extends Component {
  formRef = React.createRef(FormInstance);

  state = {
    respostaSelecionada: null,
    loadingResponder: false,
    feedback: null,
    respostasCortadas: [],
    feedbackComentario: null,
    comentarios: {
      loadingCometarios: false,
      comentarios: [],
    },
    editorState: null,
  };

  componentDidMount = () => {
    const { id } = this.props.questao;
    this.setState({
      comentarios: { loadingComentarios: true, comentarios: [] },
    });
    fetch(`${API_URL}/questao/${id}/comentario/aprovados`)
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        this.setState({
          comentarios: { loadingComentarios: false, comentarios: response },
        });
      })
      .catch((error) => {
        this.setState({
          comentarios: { loadingComentarios: false, comentarios: [] },
        });
      });
  };

  cortarResposta = (pos) => {
    var { respostasCortadas } = this.state;
    if (respostasCortadas.indexOf(pos) >= 0) {
      respostasCortadas.splice(respostasCortadas.indexOf(pos), 1);
    } else {
      respostasCortadas.push(pos);
    }
    this.setState({ respostasCortadas: respostasCortadas });
  };

  responderQuestao = () => {
    const { id } = this.props.questao;
    const { respostaSelecionada } = this.state;
    const { accessToken } = this.props.login;
    let headers = new Headers({
      Authorization: `Basic ${accessToken}`,
      "Content-Type": "application/json",
    });
    this.setState({ loadingResponder: true });
    fetch(`${API_URL}/questao/${id}/resposta/${respostaSelecionada}`, {
      method: "post",
      headers,
    })
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        if (this.props.callback) {
          this.props.callback(id, { ...response, respostaSelecionada });
        }
        this.setState({ loadingResponder: false, feedback: response });
      })
      .catch((error) => {});
  };

  onFinish = (values) => {
    this.values = values;
  };

  comentarQuestao = (values) => {
    const {
      questao: { id },
      login: { accessToken },
    } = this.props;
    this.formRef.current.setFieldsValue({ novoComentario: "" });
    let headers = new Headers({
      Authorization: `Basic ${accessToken}`,
      "Content-Type": "application/json",
    });
    let opts = {
      texto: draftToHtml(
        convertToRaw(this.state.editorState.getCurrentContent())
      ),
    };
    const body = JSON.stringify(opts);

    fetch(`${API_URL}/questao/${id}/comentario`, {
      method: "post",
      headers,
      body,
    })
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        if (!response.id) {
          swal("Erro ao tentar registrar o comentário", "", "error");
          this.setState({
            feedbackComentario: {
              correto: false,
              feedback: "Erro ao tentar registrar o comentário",
            },
          });
        } else {
          swal(
            "Comentário registrado!",
            "Aguarde a aprovação para que todos possam visualizá-lo",
            "success"
          );
          this.setState({
            feedbackComentario: {
              correto: true,
              feedback: "Comentário registrado. Aguarde a aprovação",
            },
            editorState: null,
          });
        }
      })
      .catch((error) => {
        swal("Erro ao tentar registrar o comentário", "", "error");
        this.setState({
          feedbackComentario: {
            correto: false,
            feedback: "Erro ao tentar registrar o comentário",
          },
        });
      });
    setTimeout(() => {
      this.setState({ feedbackComentario: null });
    }, 3000);
  };

  renderEnunciado2 = (enunciado) => {
    return <div dangerouslySetInnerHTML={{ __html: enunciado }} />;
  };

  renderEnunciado = (enunciado) => {
    var paragrafos = enunciado.split("\n");

    return (
      <div>
        {paragrafos.map((paragrafo, i) => {
          paragrafo = paragrafo.trim();
          var roman = ["I", "II", "III", "IV", "V"].includes(
            paragrafo.split(" ")[0]
          );
          if (roman) {
            var length = paragrafo.split(" ")[0].length;
            return (
              <p key={i}>
                <b
                  style={{
                    width: 64,
                    display: "inline-block",
                    textAlign: "center",
                  }}
                >
                  {paragrafo.split(" ")[0]}.
                </b>
                {
                  <span
                    dangerouslySetInnerHTML={{
                      __html: paragrafo[length + 1]
                        ? paragrafo[length + 1].toUpperCase() +
                          paragrafo.substr(length + 2)
                        : "",
                    }}
                  />
                }
              </p>
            );
          }
          return (
            <p
              key={i}
              dangerouslySetInnerHTML={{
                __html: paragrafo[0]
                  ? paragrafo[0].toUpperCase() + paragrafo.substring(1)
                  : "",
              }}
            />
          );
        })}
      </div>
    );
  };

  renderRespostas = (questao) => {
    const tipo = questao.tipo;
    if (tipo === "múltipla escolha 5" || tipo === "múltipla escolha 4") {
      return (
        <>
          {questao.respostas.map((r, i) => {
            if (tipo === "múltipla escolha 4" && i === 4) {
              return <></>;
            }
            return this.renderResposta(r, i);
          })}
        </>
      );
    }
    if (tipo === "certo ou errado") {
      return (
        <>
          {this.renderCertoOuErrado(questao.respostas[0], 0)}
          {this.renderCertoOuErrado(questao.respostas[1], 1)}
        </>
      );
    }
  };

  renderCertoOuErrado = (resposta, i) => {
    var classes =
      this.state.respostaSelecionada === i + 1
        ? style.respostaSelecionadaIcone
        : style.respostaIcone;
    return (
      <Row type="flex" align="middle" key={i} style={{ flexFlow: "row" }}>
        <Col flex="50px">
          <div
            onClick={() => this.setState({ respostaSelecionada: i + 1 })}
            className={classes}
          >
            {}
          </div>
        </Col>
        <Col
          flex="auto"
          style={{ marginBottom: 0, maxWidth: "calc(100% - 100px)" }}
        >
          <div>{i === 0 ? "CERTO" : "ERRADO"}</div>
        </Col>
      </Row>
    );
  };

  renderResposta = (resposta, i) => {
    const { respostasCortadas } = this.state;
    const cortada = respostasCortadas.indexOf(i + 1) >= 0;
    var classes =
      this.state.respostaSelecionada === i + 1
        ? style.respostaSelecionadaIcone
        : style.respostaIcone;

    if (!this.props.blocked) {
      if (this.state.feedback && this.state.feedback.respostaCorreta) {
        classes +=
          " " +
          (this.state.feedback.respostaCorreta === i + 1
            ? style.respostaCorreta
            : "");
      }
    }

    return (
      <Row
        type="flex"
        align="middle"
        key={i}
        className={style.respostaContainer}
        style={{ marginTop: 24, marginBottom: 24, flexFlow: "row" }}
      >
        <Col
          flex="20px"
          name="scissor-desktop"
          className={
            cortada ? style.respostaScissorsTrue : style.respostaScissors
          }
        >
          <ScissorOutlined onClick={() => this.cortarResposta(i + 1)} />
        </Col>
        <Col flex="50px">
          <div
            onClick={() => this.setState({ respostaSelecionada: i + 1 })}
            className={classes}
          >
            {String.fromCharCode(65 + i)}
          </div>
          <div
            name="scissor-mobile"
            className={
              cortada ? style.respostaScissorsTrue : style.respostaScissors
            }
          >
            <ScissorOutlined onClick={() => this.cortarResposta(i + 1)} />
          </div>
        </Col>
        <Col flex="auto" className="enunciado">
          <div
            style={{ textAlign: "justify" }}
            className={cortada ? style.respostaCortada : ""}
          >
            {resposta.texto}
          </div>
        </Col>
      </Row>
    );
  };

  setFeedback = (usuarioQuestao) => {
    if (!this.state.respostaSelecionada) {
      this.setState({
        respostaSelecionada: usuarioQuestao.opcaoEscolhida,
        feedback: {
          correto: usuarioQuestao.acerto === "true" ? true : false,
          respostaCorreta: this.props.questao.opcaoCorreta,
          feedback: this.props.questao.opcaoCorreta.feedback,
        },
      });
    }
  };

  render() {
    const { questao, blocked } = this.props;
    const {
      respostaSelecionada,
      loadingResponder,
      feedback,
      comentarios: { comentarios },
    } = this.state;
    const { usuario, isAuthenticate } = this.props.login;

    var usuarioQuestao = false;
    if (isAuthenticate && !blocked) {
      questao.usuarios_questoes.map((uq) => {
        //return 1
        if (uq.idUsuario === usuario.id) {
          usuarioQuestao = uq;
        }
        return true;
      });
    }

    if (usuarioQuestao) {
      this.setFeedback(usuarioQuestao);
    }

    return (
      <div className={style.containerQuestao}>
        <div className={style.tituloQuestao}>
          {!blocked && (
            <Link className="no-margin" to={`/questoes/${questao.url}`}>
              <LinkOutlined /> {questao.cdid}
            </Link>
          )}

          <p
            className="no-margin"
            style={{ fontFamily: "Roboto Condensed", fontSize: "2em" }}
          >
            {usuarioQuestao && !blocked ? (
              usuarioQuestao.acerto === "true" ? (
                <CheckCircleTwoTone
                  style={{ paddingRight: 8 }}
                  twoToneColor="#52c41a"
                />
              ) : (
                <CloseCircleTwoTone
                  style={{ paddingRight: 8 }}
                  twoToneColor="#ff4d4f"
                />
              )
            ) : (
              []
            )}
            {questao.status === "invalida" && (
              <div className={style.questaoInvalida}>Questão anulada</div>
            )}
            <FileTextOutlined style={{ paddingRight: 8 }} />
            <Link
              className="no-margin"
              style={{ color: "black" }}
              to={`/concursos/${questao.concurso.id}`}
            >
              {questao.concurso.orgao.nome} - {questao.concurso.nome}{" "}
            </Link>
          </p>
          <p className="no-margin">
            <b>{questao.assunto.materia.nome} -</b> {questao.assunto.nome}
          </p>
          <p className="no-margin">
            <CalendarOutlined /> {questao.concurso.ano} -{" "}
            {questao.concurso.banca.nome}
          </p>
        </div>

        <div className={style.enunciadoQuestao}>
          {this.renderEnunciado(questao.enunciado)}
        </div>

        <div className={style.respostasQuestao}>
          {this.renderRespostas(questao)}
          <Button
            disabled={
              questao.status !== "invalida" &&
              respostaSelecionada &&
              (!feedback || !blocked)
                ? false
                : true
            }
            loading={loadingResponder}
            type="primary"
            size={"large"}
            style={{ margin: "24px 34px" }}
            onClick={this.responderQuestao}
          >
            {!loadingResponder ? (
              <>
                <CheckOutlined /> Responder
              </>
            ) : (
              <>Responder</>
            )}
          </Button>
        </div>

        <div className={style.feedbackQuestao}>
          {feedback ? (
            <>
              {!blocked ? (
                <Alert
                  message={feedback.correto ? "Resposta correta!" : "Errou."}
                  description={feedback.feedback}
                  type={feedback.correto ? "success" : "error"}
                  showIcon
                />
              ) : (
                <Alert
                  message={"Questão respondida!"}
                  description={""}
                  type={"info"}
                  showIcon
                />
              )}
            </>
          ) : (
            []
          )}
        </div>

        {!blocked && (
          <div className={style.comentariosQuestao}>
            <MenuQuestao
              login={this.props.login}
              comentarios={comentarios}
              tipoQuestao={questao.tipo}
              idQuestao={questao.id}
              renderComentar={() => this.renderComentar()}
            />
          </div>
        )}
      </div>
    );
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  comentariosRecursivo = (comentarios) => {
    if (comentarios && comentarios.length > 0) {
      return comentarios.map((c) => {
        return (
          <Comentario login={this.props.login} key={c.id} comentario={c}>
            {c.comentarios.length > 0 ? (
              <Collapse
                bordered={false}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined
                    style={{ color: "#2f54eb" }}
                    rotate={isActive ? 90 : 0}
                  />
                )}
              >
                <Panel
                  style={{ backgroundColor: "white", border: 0 }}
                  header={
                    <Button
                      style={{ padding: 0, color: "#2f54eb" }}
                      type="link"
                    >
                      Ver comentários
                    </Button>
                  }
                >
                  {this.comentariosRecursivo(c.comentarios)}
                </Panel>
              </Collapse>
            ) : null}
          </Comentario>
        );
      });
    }
    return "";
  };

  renderComentar = () => {
    const { isAuthenticate } = this.props.login;
    var { editorState } = this.state;

    if (!editorState) {
      const html = "";
      const contentBlock = htmlToDraft(html);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      editorState = EditorState.createWithContent(contentState);
    }

    return isAuthenticate ? (
      <div style={{ marginTop: 16 }}>
        <Form ref={this.formRef} onFinish={this.comentarQuestao}>
          <Form.Item
            name="novoComentario"
            rules={[
              { required: true, message: "A mensagem não pode estar vazia." },
            ]}
          >
            {/*<TextArea rows={4} />*/}
            <Editor
              editorState={editorState}
              toolbarClassName={style.wysiwygToolbar}
              wrapperClassName={style.wysiwygTexto}
              editorClassName={style.wysiwygEditor}
              onEditorStateChange={this.onEditorStateChange}
              toolbar={{
                options: ["inline"],
                inline: {
                  options: ["bold", "italic", "underline", "strikethrough"],
                },
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Comentar
            </Button>
          </Form.Item>
        </Form>
      </div>
    ) : (
      <Alert
        style={{ marginTop: 16 }}
        message="Faça o login para poder comentar a questão."
        type="info"
        showIcon
      />
    );
  };
}

const mapStateToProps = (state) => ({
  login: state.login,
  comentarios: state.comentarios,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getComentarios }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Questao);
