import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Row,
  Col,
  Table,
  Tag,
  Button,
  Tooltip,
  Modal,
  message,
  Skeleton,
  Descriptions,
  Radio,
  Switch,
  Tabs,
} from "antd";
import { API_URL } from "../../../../configs/config";
import { useSelector } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import SimuladosFinalizados from "./finalizados";
import {
  LinkOutlined,
  EditOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  //Facebook
  FacebookShareButton,
  FacebookIcon,
  //Email
  EmailShareButton,
  EmailIcon,
  //Twitter
  TwitterShareButton,
  TwitterIcon,
  //Telegram
  TelegramShareButton,
  TelegramIcon,
  //Whatsapp
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import style from "./style.module.css";
import { useHistory } from "react-router-dom";

const { confirm } = Modal;
const { TabPane } = Tabs;

function MeusSimulados({ match }) {
  const login = useSelector((state) => state.login);
  const history = useHistory();
  if (!login.isAuthenticate) {
    history.push("/");
  }

  const [loadSimulados, setLoadSimulados] = useState(false);
  const [simulados, setSimulados] = useState([]);

  useEffect(
    function () {
      getSimulados(login);
    },
    [login]
  );

  async function getSimulados(login) {
    setLoadSimulados(true);
    let headers = new Headers({
      Authorization: `Basic ${login.accessToken}`,
      "Content-Type": "application/json",
    });
    const simuladoResponse = await fetch(`${API_URL}/simulados/meussimulados`, {
      method: "get",
      headers,
    });
    const _simuladoResponse = await simuladoResponse.json();
    setSimulados(_simuladoResponse.data);
    setLoadSimulados(false);
  }

  const columns = [
    {
      title: "Titulo",
      dataIndex: "titulo",
      key: "titulo",
      render: (titulo, simulado) => {
        const current_time = Math.floor(Date.now() / 1000);
        const expire_time = moment(simulado.expire_at).format("X");
        if (simulado.ativo === "FALSE" || expire_time <= current_time) {
          return titulo;
        } else {
          return <Link to={`/quiz/${simulado.hash}`}>{titulo}</Link>;
        }
      },
    },
    {
      title: "Ativo",
      dataIndex: "ativo",
      key: "ativo",
      width: 60,
      className: "d-none d-md-table-cell",
      render: (ativo) => {
        return (
          <Tag
            style={{ width: 50, textAlign: "center" }}
            color={ativo === "TRUE" ? "blue" : "red"}
          >
            {ativo === "TRUE" ? "ativo" : "inativo"}
          </Tag>
        );
      },
    },
    {
      title: "Visibilidade",
      dataIndex: "visibilidade",
      key: "visibilidade",
      width: 60,
      className: "d-none d-md-table-cell",
      render: (visibilidade) => {
        var color = "red";
        switch (visibilidade) {
          case "particular": {
            color = "red";
            break;
          }
          case "pública": {
            color = "blue";
            break;
          }
          case "privada": {
            color = "orange";
            break;
          }
          default:
            color = "red";
        }
        return <Tag color={color}>{visibilidade}</Tag>;
      },
    },
    {
      title: "Criação",
      dataIndex: "created_at",
      key: "created_at",
      width: 90,
      className: "d-none d-md-table-cell",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        moment(a.created_at).format("X") - moment(b.created_at).format("X"),
      render: (created_at) => moment(created_at).fromNow(),
    },
    {
      title: "Expiração",
      dataIndex: "expire_at",
      key: "expire_at",
      width: 90,
      className: "d-none d-md-table-cell",
      sorter: (a, b) =>
        moment(a.expire_at).format("X") - moment(b.expire_at).format("X"),
      render: (expire_at) => {
        if (!expire_at) {
          return null;
        } else {
          const current_time = Math.floor(Date.now() / 1000);
          const expire_time = moment(expire_at).format("X");
          return expire_time > current_time ? (
            moment(expire_at).fromNow()
          ) : (
            <Tag color={"red"}>expirado</Tag>
          );
        }
      },
    },
    {
      title: "Ações",
      dataIndex: "id",
      width: 140,
      render: (id, hash) => {
        return (
          <div style={{ width: 100 }}>
            <Tooltip placement="top" title={"Editar"}>
              <Link to={`/quiz/editar/${id}`}>
                <Button size="small">
                  <EditOutlined />
                </Button>
              </Link>
            </Tooltip>{" "}
            <Tooltip placement="top" title={"Copiar link"}>
              <CopyToClipboard
                text={`https://cadedireito.com.br/simulado/${hash.hash}`}
                onCopy={() =>
                  message.success("Link para o quiz copiado com sucesso!")
                }
              >
                <Button size="small">
                  <LinkOutlined />
                </Button>
              </CopyToClipboard>
            </Tooltip>{" "}
            <Tooltip placement="top" title={"Remover"}>
              <Button
                id={id}
                size="small"
                danger
                onClick={() => removerSimulado(id)}
              >
                <CloseCircleOutlined />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  async function onRemoverSimulado(id) {
    let headers = new Headers({
      Authorization: `Basic ${login.accessToken}`,
      "Content-Type": "application/json",
    });
    const _simuladoResponse = await fetch(`${API_URL}/simulados/${id}`, {
      method: "delete",
      headers,
    });
    const simuladoResponse = await _simuladoResponse.json();

    if (simuladoResponse.sucesso) {
      message.success("Quiz removido com sucesso!");
      setSimulados(simuladoResponse.data);
    } else {
      message.error("Erro ao tentar remover o quiz!");
    }
  }

  function removerSimulado(id) {
    confirm({
      title: "Deseja realmente remover este quiz?",
      icon: <ExclamationCircleOutlined />,
      content: "Esta operação não pode ser desfeita.",
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      onOk() {
        onRemoverSimulado(id);
      },
      onCancel() {},
    });
  }

  async function ativacaoSimulado(checked, id) {
    const opts = {
      ativo: checked ? "TRUE" : "FALSE",
    };
    let headers = new Headers({
      Authorization: `Basic ${login.accessToken}`,
      "Content-Type": "application/json",
    });
    const body = JSON.stringify(opts);
    const _simuladoResponse = await fetch(`${API_URL}/simulados/${id}`, {
      method: "put",
      headers,
      body,
    });
    const simuladoResponse = await _simuladoResponse.json();
    if (simuladoResponse) {
      message.success("Quiz alterado com sucesso!");
      getSimulados(login);
    } else {
      message.error("Erro ao tentar alterar o quiz!");
    }
  }

  async function visibilidadeSimulado(visibilidade, id) {
    setLoadSimulados(true);
    const opts = {
      visibilidade,
    };
    let headers = new Headers({
      Authorization: `Basic ${login.accessToken}`,
      "Content-Type": "application/json",
    });
    const body = JSON.stringify(opts);
    const _simuladoResponse = await fetch(`${API_URL}/simulados/${id}`, {
      method: "put",
      headers,
      body,
    });
    const simuladoResponse = await _simuladoResponse.json();
    if (simuladoResponse) {
      message.success("Quiz alterado com sucesso!");
      await getSimulados(login);
    } else {
      message.error("Erro ao tentar alterar o quiz!");
    }
    setLoadSimulados(false);
  }

  const size = 24;
  return (
    <>
      <Row gutter={[24, 24]}>
        <Col>
          <Button type="primary">
            <Link to={`/quiz/novo`}>Criar novo quiz</Link>
          </Button>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <h5>Meus quiz</h5>
          {loadSimulados ? (
            <Skeleton></Skeleton>
          ) : (
            <Table
              rowKey={"id"}
              columns={columns}
              dataSource={simulados}
              size="small"
              expandable={{
                expandedRowRender: (simulado) => {
                  return (
                    <div style={{ marginBottom: 48 }}>
                      <Tabs defaultActiveKey="1">
                        <TabPane tab="Dados do quiz" key="1">
                          <Descriptions size="small" column={1}>
                            <Descriptions.Item
                              label="Ativo"
                              className={style.switch}
                            >
                              <Switch
                                size="small"
                                checked={
                                  simulado.ativo === "TRUE" ? true : false
                                }
                                onChange={(checked) =>
                                  ativacaoSimulado(checked, simulado.id)
                                }
                              />
                            </Descriptions.Item>
                            <Descriptions.Item label="Visibilidade">
                              <Radio.Group
                                defaultValue={simulado.visibilidade}
                                size="small"
                                onChange={(ev) =>
                                  visibilidadeSimulado(
                                    ev.target.value,
                                    simulado.id
                                  )
                                }
                              >
                                <Radio.Button value="particular" checked>
                                  Particular
                                </Radio.Button>
                                <Radio.Button value="privada">
                                  Privada
                                </Radio.Button>
                                <Radio.Button value="pública">
                                  Pública
                                </Radio.Button>
                              </Radio.Group>
                            </Descriptions.Item>
                            <Descriptions.Item label="Mostrar resultado?">
                              {simulado.mostrarresultado === "TRUE"
                                ? "Sim"
                                : "Não"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Mostrar gabarito?">
                              {simulado.mostrargabarito === "TRUE"
                                ? "Sim"
                                : "Não"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Quantidade de questões">
                              <>{simulado.simulado_questoes.length}</>
                              <Link
                                style={{ marginLeft: 12 }}
                                to={`/quiz/editar/${simulado.id}`}
                              >
                                + Adicionar
                              </Link>
                            </Descriptions.Item>
                            <Descriptions.Item label="Tipo">
                              {simulado.tipo}
                            </Descriptions.Item>
                            <Descriptions.Item label="Data de criação">
                              {moment(simulado.created_at).format(
                                "DD/MM/YYYY H:mm:ss"
                              )}
                            </Descriptions.Item>
                            <Descriptions.Item label="Última alteração">
                              {moment(simulado.updated_at).format(
                                "DD/MM/YYYY H:mm:ss"
                              )}
                            </Descriptions.Item>
                            <Descriptions.Item label="Data de expiração">
                              {moment(simulado.expire_at).format(
                                "DD/MM/YYYY H:mm:ss"
                              )}
                            </Descriptions.Item>

                            <Descriptions.Item label="Compartilhe">
                              <Tooltip title="Compartilhe com o Facebook">
                                <FacebookShareButton
                                  url={
                                    "https://www.cadedireito.com.br/simulado/" +
                                    simulado.hash
                                  }
                                  quote={"Quiz: " + simulado.titulo}
                                  className={style.sharesButton}
                                >
                                  <FacebookIcon
                                    size={size}
                                    round
                                    className={style.sharesButtonIcon}
                                  />
                                </FacebookShareButton>
                              </Tooltip>
                              <Tooltip title="Compartilhe com o Twitter">
                                <TwitterShareButton
                                  url={
                                    "https://www.cadedireito.com.br/simulado/" +
                                    simulado.hash
                                  }
                                  title={"Quiz: " + simulado.titulo}
                                  className={style.sharesButton}
                                >
                                  <TwitterIcon
                                    size={size}
                                    round
                                    className={style.sharesButtonIcon}
                                  />
                                </TwitterShareButton>
                              </Tooltip>
                              <Tooltip title="Compartilhe com o Telegram">
                                <TelegramShareButton
                                  url={
                                    "https://www.cadedireito.com.br/simulado/" +
                                    simulado.hash
                                  }
                                  title={"Quiz: " + simulado.titulo}
                                  className={style.sharesButton}
                                >
                                  <TelegramIcon
                                    size={size}
                                    round
                                    className={style.sharesButtonIcon}
                                  />
                                </TelegramShareButton>
                              </Tooltip>
                              <Tooltip title="Compartilhe com seus contatos do Whatsapp">
                                <WhatsappShareButton
                                  url={
                                    "https://www.cadedireito.com.br/simulado/" +
                                    simulado.hash
                                  }
                                  title={"Quiz: " + simulado.titulo}
                                  separator=":: "
                                  className={style.sharesButton}
                                >
                                  <WhatsappIcon
                                    size={size}
                                    round
                                    className={style.sharesButtonIcon}
                                  />
                                </WhatsappShareButton>
                              </Tooltip>
                              <Tooltip title="Compartilhe por E-mail">
                                <EmailShareButton
                                  url={
                                    "https://www.cadedireito.com.br/simulado/" +
                                    simulado.hash
                                  }
                                  subject={"Quiz: " + simulado.titulo}
                                  body="body"
                                  className={style.sharesButton}
                                >
                                  <EmailIcon
                                    size={size}
                                    round
                                    className={style.sharesButtonIcon}
                                  />
                                </EmailShareButton>
                              </Tooltip>
                            </Descriptions.Item>
                          </Descriptions>
                        </TabPane>
                        <TabPane tab="Finalizados" key="2">
                          <SimuladosFinalizados
                            simulado={simulado}
                            login={login}
                          ></SimuladosFinalizados>
                        </TabPane>
                      </Tabs>
                    </div>
                  );
                },
                rowExpandable: (simulado) => simulado.titulo !== "",
              }}
            />
          )}
        </Col>
      </Row>
    </>
  );
}

export default withRouter(MeusSimulados);
