import React from 'react'

import Search from '../Search'
import Authenticate from '../Authenticate'
import style from './style.module.css'
import "antd/dist/antd.css"
import { Affix } from 'antd'

export default props => {

  const { search = false, authenticate = true } = props || {}

  return (
    <Affix offsetTop={0}>
    <header className={style.supportBar}>
      <div className="container">
        <div className="flex">
          {
            search
              ? <Search />
              : []
          }
          {
            authenticate
              ? <Authenticate />
              : []
          }
        </div>
      </div>
      </header>
    </Affix>
  );
}