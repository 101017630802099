import React, { Component } from 'react'
import bannerImage from '../../images/banner-1.webp'
import style from './style.module.css'
import { withRouter } from "react-router"

class BannerBar extends Component {
  render() {
    const { pathname } = this.props.location
    const { customText } = this.props

    var titulo = "Cadê Direito"
    switch (pathname) {
      case '/questoes': {
        titulo = '/questões'
        break
      }
      case '/orgaos': {
        titulo = '/órgãos'
        break
      }
      default: {
        titulo = '/'+pathname.split('/')[1]
      }
    }
    return (
      <div style={sectionStyle} className={style.sectionStyle}>
        <div className={style.bannerTitulo}>
          <div className="container">
            <h1>{customText ? customText : titulo}</h1>
          </div>
        </div>
        <div className={style.bannerImageOverlay}>
          
        </div>
      </div>
    )
  }
}

var sectionStyle = {
  position: 'relative',
  width: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'left center',
  backgroundColor: '#727274',
  backgroundImage: `url(${bannerImage})`,
};

export default withRouter(BannerBar)