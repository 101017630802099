import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getQuestoes } from "../../actions/Questoes.action";
import FiltroQuestoes from "../../components/FiltroQuestoes";
import Questao from "../../components/Questao";
import style from "./style.module.css";
import { withRouter } from "react-router";
import RenderHelmet from "../../components/RenderHelmet";
import ReactGa from "react-ga";
import { GA_TRACKING_ID } from "../../configs/config";
import { Pagination, Skeleton, Empty } from "antd";

class Questoes extends Component {
  state = {
    filtros: {
      disciplinas: [],
      assuntos: [],
      concursos: [],
      orgaos: [],
      bancas: [],
      cargos: [],
      estados: [],
      anos: [],
      anoInicial: 1988,
      anoFinal: 2021,
      exibir: "todas",
      enunciado: "",
    },
    page: "1",
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    const { search } = this.props.location;
    this.setFiltros(search.substr(1));
    const params = "&" + search.substr(1);
    const page = this.state.page;
    ReactGa.initialize(GA_TRACKING_ID);
    ReactGa.pageview(window.location.pathname + window.location.search);
    this.props.getQuestoes(page, params);
  };

  onChangePage = (page) => {
    this.setState({ page: page });
    var params = this.getFiltros();
    const { accessToken = null } = this.props.login || [];
    this.props.getQuestoes(page, params, accessToken);
  };

  setFiltros = (params) => {
    if (!params) {
      return;
    }
    const filtros = params.split("&");
    filtros.map((filtro) => {
      filtro = filtro.replace("[]", "");
      filtro = filtro.split("=");
      var key = filtro[0];
      key = key === "materias" ? "disciplinas" : key;
      if (
        ![
          "orgaos",
          "assuntos",
          "materias",
          "bancas",
          "concursos",
          "cargos",
        ].includes(key)
      ) {
        return false;
      }
      var value = this.state.filtros[filtro[0]];
      value.push(filtro[1]);
      return this.setState({
        filtros: { ...this.state.filtros, [key]: value },
      });
    });
  };

  getFiltros = () => {
    const {
      disciplinas,
      assuntos,
      concursos,
      cargos,
      orgaos,
      bancas,
      estados,
      anos,
      anoInicial,
      anoFinal,
      tipo,
      exibir,
    } = this.state.filtros;
    var params = "";
    disciplinas.map((d) => (params += `&materias[]=${d}`));
    assuntos.map((a) => (params += `&assuntos[]=${a}`));
    concursos.map((c) => (params += `&concursos[]=${c}`));
    cargos.map((g) => (params += `&cargos[]=${g}`));
    orgaos.map((o) => (params += `&orgaos[]=${o}`));
    bancas.map((b) => (params += `&bancas[]=${b}`));
    estados.map((e) => (params += `&estados[]=${e}`));
    anos.map((y) => (params += `&anos[]=${y}`));
    switch (tipo) {
      case 1: {
        params += "&tipo=múltipla escolha 5";
        break;
      }
      case 2: {
        params += "&tipo=múltipla escolha 4";
        break;
      }
      case 3: {
        params += "&tipo=certo ou errado";
        break;
      }
      default: {
      }
    }
    params += `&anoInicial=${anoInicial}&anoFinal=${anoFinal}`;
    params += `&exibir=${exibir}`;
    return params;
  };

  filtros = (filtros) => {
    this.setState({ filtros: filtros, page: "1" });
    var params = this.getFiltros();
    const page = this.state.page;
    const { accessToken = null } = this.props.login || [];
    this.props.getQuestoes(page, params, accessToken);
  };

  render() {
    const { questoes, loadQuestoes, count, curPage } = this.props.questoes;
    return (
      <>
        <RenderHelmet
          title={"Cade Direito: Buscar questões de concursos"}
          description="Busque, localize, filtre questões de concursos na área de direito"
        />
        <div className={style.backgroundCinza}>
          <div className="container">
            <FiltroQuestoes
              filtros={(filtros) => this.filtros(filtros)}
            ></FiltroQuestoes>
          </div>
        </div>
        <div className="container" style={{ marginTop: 24 }}>
          {!loadQuestoes ? (
            <>
              <Pagination
                defaultCurrent={curPage ? curPage : "1"}
                showSizeChanger={false}
                defaultPageSize={5} //default size of page
                total={count} //total number of card data available
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} de ${total} questões`
                }
                style={{ marginBottom: 24, textAlign: "right" }}
                onChange={this.onChangePage}
              />
              {questoes && questoes.length > 0 ? (
                questoes.map((q) => {
                  return <Questao key={q.id} questao={q}></Questao>;
                })
              ) : (
                <Empty
                  description={
                    <>
                      <span>
                        Não há questões disponíveis que atendam a pesquisa!
                      </span>
                      <br />
                      <span>Verifique os filtros e pesquise novamente.</span>
                    </>
                  }
                  style={{ margin: "64px 0 128px 0" }}
                />
              )}
              <Pagination
                defaultCurrent={curPage ? curPage : "1"}
                showSizeChanger={false}
                defaultPageSize={5} //default size of page
                total={count} //total number of card data available
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} de ${total} questões`
                }
                style={{ marginBottom: 24, textAlign: "right" }}
                onChange={(page) => {
                  this.onChangePage(page);
                  window.scrollTo(0, 510);
                }}
              />
            </>
          ) : (
            <>
              <div style={{ textAlign: "right" }}>
                <Skeleton.Input
                  style={{ height: 48, marginBottom: 24, width: 320 }}
                  active
                />
              </div>
              <Skeleton active paragraph={{ rows: 12 }}></Skeleton>
              <br />
              <Skeleton active paragraph={{ rows: 12 }}></Skeleton>
              <br />
              <Skeleton active paragraph={{ rows: 12 }}></Skeleton>
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  questoes: state.questoes,
  login: state.login,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getQuestoes }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Questoes));
