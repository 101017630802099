const INITIAL_STATE = {
  isAuthenticate: false,
  loadLogin: false,
  isFailure: false,
  accessToken: null,
  expires: null,
  usuario: null
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOGIN_REQUEST': {
      return {
        ...state,
        loadLogin: true
      }
    }
    case 'LOGIN_SUCCESS': {
      return {
        ...state,
        isAuthenticate: true,
        loadLogin: false,
        isFailure: false,
        accessToken: action.payload.accessToken,
        usuario: action.payload.usuario,
        expires: new Date().getTime() + (10 * 60000) //10 minutos
      }
    }
    case 'LOGIN_FAILURE': {
      return {
        ...state,
        isAuthenticate: false,
        loadLogin: false,
        isFailure: true
      }
    }
    case 'DESLOGAR': {
      return {
        ...INITIAL_STATE,
      }
    }
    case 'LOGIN_RESET': {
      return {
        ...INITIAL_STATE,
      }
    }
    default: {
      return state
    }
  }
}