const INITIAL_STATE = {
  loadRegistro: false,
  message: false
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    /** STORE */
    case 'STORE_REGISTRO_REQUEST': {
      return { ...state, loadRegistro: true }
    }
    case 'STORE_REGISTRO_SUCCESS': {
      return { ...state, loadRegistro: false, status: 'success', message: action.payload }
    }
    case 'STORE_REGISTRO_FAILURE': {
      return { ...state, loadRegistro: false, status: 'error', message: action.payload }
    }

    /** DEFAULT */
    default: { return state }
  }
}