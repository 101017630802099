import styled from "styled-components";
import { Input, Button } from "antd";

export const Container = styled.div`
  margin: 0px 96px 48px 96px;
  @media (max-width: 768px) {
    margin: 0 0 24px 0;
  }
`;

export const Title = styled.p`
  font-family: "Roboto Condensed";
  font-size: 1.5em;
  color: var(--primary);
  font-weight: bold;
`;
export const SearchBar = styled(Input)`
  border-radius: 24px;
  input {
    padding: 8px !important;
  }
`;
export const ButtonClose = styled(Button)`
  color: rgba(0, 0, 0, 0.85);
  :hover {
    color: rgba(0, 0, 0, 0.65);
  }
`;

export const SearchBarButton = styled(Button)`
  background-color: var(--primary3);
  border: solid 1px var(--primary3);
  font-weight: bold;
  color: white;
  height: calc(100% + 10px);
  border-radius: 0 25px 25px 0;
  margin-right: -13px;
  padding: 0 24px;

  @media (max-width: 768px) {
    display: none;
    .ant-btn {
      padding: 4px;
    }
  }
`;

export const SearchBarButtonMobile = styled(Button)`
  background-color: var(--primary3);
  border: solid 1px var(--primary3);
  font-weight: bold;
  color: white;
  border-radius: 24px;
  padding: 20px 0;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  margin-top: 8px;
  @media (min-width: 769px) {
    display: none;
  }
`;
