import styled from "styled-components";
import { Col } from "antd";

export const Container = styled.div`
  overflow: hidden;
  @media (max-width: 768px) {
    text-align: center;
    button {
      margin: 4px 0px;
    }
  }
  .ant-badge-status-dot {
    width: 12px;
    height: 12px;
  }
`;

export const ColMaterias = styled(Col)`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const ColChart = styled(Col)`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ChartContainer = styled.div`
  width: 200%;
  overflow: hidden;
  margin-left: -50%;
  .recharts-wrapper {
    margin: auto;
  }
`;

export const BoxProva = styled.div`
  margin-top: 24px;
  background-color: var(--light-gray);
  padding: 24px 48px;
  width: 100%;
  @media (max-width: 992px) {
    padding: 24px;
  }
`;

export const Titulo = styled.p`
  font-size: 1.5em;
  font-family: "Roboto Condensed";
  font-weight: bold;
  color: var(--primary);
  margin-bottom: 0;
`;

export const SimuladoInfo = styled.div`
  background-color: var(--light-gray);
  box-shadow: 0 1px 4px -2px black;
  margin-bottom: 12px;
  width: 216px;
  margin: 12px 2px 12px auto;
  padding: 0 8px 12px 24px;
  opacity: 0.9;
  .timer-display {
    margin-bottom: 0;
    font-size: 1.5em;
    font-family: "Roboto Condensed";
    color: var(--primary);
    font-weight: bold;
    text-align: center;
  }
`;

export const Round = styled.div`
  width: 24px;
  height: 24px;
  font-weight: bold;
  display: block;
  color: #646464;
  text-align: center;
  border: solid 1px #646464;
  border-radius: 50%;
`;
