import React, { useEffect } from "react";
import { Button, Row, Col } from "antd";
import { Container, Title } from "./styles";
import { Helmet } from "react-helmet";
import ReactGa from "react-ga";
import { GA_TRACKING_ID } from "../../configs/config";
import Img from "../../images/grupodeestudos.jpg";

function Grupodeestudos() {
  useEffect(() => {
    ReactGa.initialize(GA_TRACKING_ID);
    ReactGa.pageview("/grupo-de-estudos");
  }, []);

  return (
    <Container className="container mt-5 mb-5">
      <Helmet>
        <title>{`Grupo de estudos`}</title>
        <meta
          name="description"
          content={`Grupo de estudos Cadê Direito`}
        ></meta>
        <meta name="robots" content="index, follow"></meta>
      </Helmet>
      <Row>
        <Col sm={{ span: 24 }} md={{ span: 12 }}>
          <Title>Grupo de estudos</Title>
          <p>
            O Grupo de Estudos do Cadê Direito é um canal voltado a publicação
            de questões comentadas de concursos públicos e atualizações
            jurídicas relevantes para o estudante e profissional do Direito.
          </p>
          <p>
            Estamos sempre atualizando e abrindo novas vagas em nosso grupo para
            que você sempre tenha a chance de participar.
          </p>
          <p>A participação no grupo é GRATUITA!</p>
          <p className="mt-5 mb-5">Ficou interessado(a)?</p>
          <p>Clique no botão abaixo e participe do nosso grupo no Telegram!</p>
          <a
            href="http://t.me/cadedireito"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Button type="primary" htmlType="button" shape="round" size="large">
              Acessar o Grupo de estudos
            </Button>
          </a>
        </Col>
        <Col sm={{ span: 24 }} md={{ span: 12 }}>
          <img src={Img} alt="estudantes" />
        </Col>
      </Row>
    </Container>
  );
}

export default Grupodeestudos;
