export const API_URL = 'https://api.cadedireito.com.br/v1';

export const INSTAGRAM_URL = 'https://www.instagram.com/cade.direito'
export const TELEGRAM_URL = 'https://t.me/cadedireito'
//export const YOUTUBE_URL = 'https://www.youtube.com.br/channel/UCNUhyucjnR-raToyc_55F2Q'
export const YOUTUBE_URL = 'https://bit.ly/3d8uBFn'
export const FACEBOOK_URL = 'http://www.facebook.com.br/cdireito'

var __RECAPTCHA_ID
var __GA_TRACKING_ID
if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
  __RECAPTCHA_ID = '6LcPDewUAAAAADRrThmIgg04qkKgTtqyz5ye4hCs'
  __GA_TRACKING_ID = 'UA-169214910-1'
} else { 
  __RECAPTCHA_ID = '6LceDOwUAAAAAERRgivv0neACn7aweXhd_loca3l'
  __GA_TRACKING_ID = 'UA-169214910-2'
}

export const RECAPTCHA_ID = __RECAPTCHA_ID;
export const GA_TRACKING_ID = __GA_TRACKING_ID;