import React, { useState, useEffect } from "react";
import { API_URL } from "../../../../configs/config";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import { Col, Row, Table, Tag, Descriptions, Switch } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import style from "./style.module.css";

function Participando() {
  //Selectors
  const login = useSelector((state) => state.login);

  //States
  const [loadSimulados, setLoadSimulados] = useState(false);
  const [loadingSimulados, setLoadingSimulados] = useState(false);
  const [simulados, setSimulados] = useState([]);

  // Effects
  useEffect(() => {
    async function getSimulados(login) {
      setLoadingSimulados(true);
      let headers = new Headers({
        Authorization: `Basic ${login.accessToken}`,
        "Content-Type": "application/json",
      });
      const _simuladosResponse = await fetch(
        `${API_URL}/simulados/participando`,
        {
          method: "get",
          headers,
        }
      );
      const simuladosResponse = await _simuladosResponse.json();
      setSimulados(simuladosResponse.data);
      setLoadSimulados(true);
      setLoadingSimulados(false);
    }
    getSimulados(login);
  }, [login]);

  const columnsSimulados = [
    {
      title: "Titulo",
      dataIndex: "simulado",
      key: "1",
      render: (simulado) => {
        const current_time = Math.floor(Date.now() / 1000);
        const expire_time = moment(simulado.expire_at).format("X");
        if (simulado.ativo === "FALSE" || expire_time <= current_time) {
          return simulado.titulo;
        } else {
          return <Link to={`/quiz/${simulado.hash}`}>{simulado.titulo}</Link>;
        }
      },
    },
    {
      title: "Criador",
      dataIndex: "simulado",
      key: "criador",
      render: (simulado) => {
        return <span>{simulado.dono.usuario}</span>;
      },
    },
    {
      title: "Ativo",
      dataIndex: "simulado",
      key: "ativo",
      className: "d-none d-md-table-cell",
      width: 60,
      render: (simulado) => {
        return (
          <Tag
            style={{ width: 50, textAlign: "center" }}
            color={simulado.ativo === "TRUE" ? "blue" : "red"}
          >
            {simulado.ativo === "TRUE" ? "ativo" : "inativo"}
          </Tag>
        );
      },
    },
    {
      title: "Convite",
      dataIndex: "simulado",
      key: "convite",
      width: 120,
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        moment(a.created_at).format("X") - moment(b.created_at).format("X"),
      render: (simulado, convite) => moment(convite.created_at).fromNow(),
    },
    {
      title: "Criação",
      dataIndex: "simulado",
      className: "d-none d-md-table-cell",
      key: "created_at",
      width: 120,
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        moment(a.created_at).format("X") - moment(b.created_at).format("X"),
      render: (simulado) => moment(simulado.created_at).fromNow(),
    },
    {
      title: "Expiração",
      dataIndex: "simulado",
      className: "d-none d-md-table-cell",
      key: "expire_at",
      width: 90,
      sorter: (a, b) =>
        moment(a.expire_at).format("X") - moment(b.expire_at).format("X"),
      render: (simulado) => {
        if (!simulado.expire_at) {
          return null;
        } else {
          const current_time = Math.floor(Date.now() / 1000);
          const expire_time = moment(simulado.expire_at).format("X");
          return expire_time > current_time ? (
            moment(simulado.expire_at).fromNow()
          ) : (
            <Tag color={"red"}>expirado</Tag>
          );
        }
      },
    },
  ];

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        {loadSimulados && !loadingSimulados ? (
          <>
            <h5>Convites recebidos</h5>
            <Table
              rowKey="id"
              size="small"
              columns={columnsSimulados}
              dataSource={simulados}
              expandable={{
                expandedRowRender: (simulado) => {
                  return (
                    <div style={{ marginBottom: 48 }}>
                      <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Criador">
                          {simulado.simulado.dono.usuario}
                        </Descriptions.Item>
                        <Descriptions.Item label="Convite">
                          {moment(simulado.created_at).format(
                            "DD/MM/YYYY H:mm:ss"
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item
                          label="Ativo"
                          className={style.switch}
                        >
                          <Switch
                            size="small"
                            disabled
                            checked={
                              simulado.simulado.ativo === "TRUE" ? true : false
                            }
                          />
                        </Descriptions.Item>
                        <Descriptions.Item label="Visibilidade">
                          {simulado.simulado.visibilidade}
                        </Descriptions.Item>
                        <Descriptions.Item label="Tipo">
                          {simulado.simulado.tipo}
                        </Descriptions.Item>
                        <Descriptions.Item label="Data de criação">
                          {moment(simulado.simulado.created_at).format(
                            "DD/MM/YYYY H:mm:ss"
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Data de expiração">
                          {moment(simulado.simulado.expire_at).format(
                            "DD/MM/YYYY H:mm:ss"
                          )}
                        </Descriptions.Item>
                      </Descriptions>
                    </div>
                  );
                },
              }}
            />
          </>
        ) : (
          <></>
        )}
      </Col>
    </Row>
  );
}

export default withRouter(Participando);
