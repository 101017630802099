import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import BannerBar from "../../../components/Bannerbar";
import { Container, LegislacaoText, Preambulo } from "./styles";
import { API_URL } from "../../../configs/config";

export default function LegislacaoFederal() {
  const [legislacaoFederal, setLegislacaoFederal] = useState([]);

  useEffect(() => {
    async function getLegislacao() {
      const _ = await fetch(`${API_URL}/legislacao/federal`, {
        method: "get",
      });
      const legislacaoResponse = await _.json();
      console.log(legislacaoResponse);
      setLegislacaoFederal(legislacaoResponse.data);
    }
    getLegislacao();
  }, []);

  return (
    <>
      <BannerBar />
      <Container className="container mt-5 mb-5">
        <Helmet>
          <title>{`Legislação`}</title>
          <meta name="description" content={`Legislação`}></meta>
          <meta name="robots" content="index, follow"></meta>
        </Helmet>
        <LegislacaoText>
          <div className="text-center">
            <h3 className="header-title">
              CONSTITUIÇÃO DA REPÚBLICA FEDERATIVA DO BRASIL DE 1988
            </h3>
          </div>
          <Preambulo className="mt-5 mb-5">
            <h3 className="titulo">
              <strong>PREÂMBULO</strong>
            </h3>
            <p>
              Nós, representantes do povo brasileiro, reunidos em Assembléia
              Nacional Constituinte para instituir um Estado Democrático,
              destinado a assegurar o exercício dos direitos sociais e
              individuais, a liberdade, a segurança, o bem-estar, o
              desenvolvimento, a igualdade e a justiça como valores supremos de
              uma sociedade fraterna, pluralista e sem preconceitos, fundada na
              harmonia social e comprometida, na ordem interna e internacional,
              com a solução pacífica das controvérsias, promulgamos, sob a
              proteção de Deus, a seguinte CONSTITUIÇÃO DA REPÚBLICA FEDERATIVA
              DO BRASIL.
            </p>
          </Preambulo>
          {legislacaoFederal &&
            legislacaoFederal.map((el, key) => {
              console.log(el);
              return (
                <p className={el.tipo} key={"t" + key}>
                  <span className="num">{el.titulo} </span>
                  {el.texto}
                </p>
              );
            })}
        </LegislacaoText>
      </Container>
    </>
  );
}
