import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, message, Modal } from "antd";
import {
  EditOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { putQuestoes } from "../../../actions/Quiz.action";

import ModalNovaQuestao from "./modalNovaQuestao";
import ModalEditarQuestao from "./modalEditarQuestao";

import {
  Container,
  Questao,
  Enunciado,
  ContainerRespostas,
  Resposta,
  CertoErrado,
} from "./styles";
import { useSelector } from "react-redux";

const { confirm } = Modal;

export default function QuestoesQuiz({ questoesSelector }) {
  const dispatch = useDispatch();

  const [showModalNovaQuestao, setShowModalNovaQuestao] = useState(false);
  const [showModalEditarQuestao, setShowModalEditarQuestao] = useState(false);
  const [questoes, setQuestoes] = useState([]);
  const [questaoSelecionada, setQuestaoSelecionada] = useState({
    questao: null,
    pos: null,
  });

  // const questoesSelector = useSelector((state) => state.quiz.questoes);
  const { tipo } = useSelector((state) => state.quiz);

  useEffect(() => {
    setQuestoes(questoesSelector);
  }, [questoesSelector, setQuestoes]);

  function removerQuestao(pos) {
    confirm({
      title: "Deseja remover a questão?",
      icon: <ExclamationCircleOutlined />,
      content: "Após a confirmação, esta operação não pode ser desfeita.",
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      onOk() {
        const _questoes = questoes.filter((q, key) => pos !== key);
        message.success("Questão removida com sucesso!");
        dispatch(putQuestoes(_questoes));
      },
      onCancel() {},
    });
  }

  const Item = ({ questao, pos }) => {
    return (
      <Questao key={pos}>
        <div className="menu">
          <Button
            size="small"
            onClick={() => {
              setQuestaoSelecionada({ pos, questao });
              return setShowModalEditarQuestao(true);
            }}
          >
            <EditOutlined /> Editar
          </Button>{" "}
          <Button
            size="small"
            id={questao.id}
            danger
            onClick={() => removerQuestao(pos)}
          >
            <CloseCircleOutlined /> Remover
          </Button>
        </div>
        <span className="numero-questao">Questão {pos + 1}</span>
        <Enunciado
          dangerouslySetInnerHTML={{
            __html: questao.enunciado,
          }}
        ></Enunciado>
        <ContainerRespostas>
          {questao.respostas.map((sqr, index) => {
            if (tipo === "certo ou errado" && index === 0) {
              return (
                <CertoErrado key={"certo-errado-" + index}>
                  <div className="resposta">Certo</div> ou{" "}
                  <div className="resposta">Errado</div> ?
                </CertoErrado>
              );
            }
            if (tipo === "certo ou errado" && index > 0) {
              return [];
            }
            if (tipo === "múltipla escolha 4" && index === 4) {
              return [];
            }
            return (
              <Resposta key={"resposta-" + index} value={index}>
                {sqr.texto}
              </Resposta>
            );
          })}
        </ContainerRespostas>
      </Questao>
    );
  };

  const List = ({ questoes }) => {
    if (questoes) {
      return (
        <ul style={{ paddingLeft: 0 }}>
          {questoes &&
            questoes.map((questao, index) => {
              return (
                <Item key={"item-" + index} pos={index} questao={questao} />
              );
            })}
        </ul>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Container>
      <div className="mb-3">
        <List questoes={questoes} />
        <Button type="primary" onClick={() => setShowModalNovaQuestao(true)}>
          Adicionar questão
        </Button>
      </div>
      {!showModalNovaQuestao || (
        <ModalNovaQuestao
          idQuiz={"3"}
          tipo={tipo}
          login={null}
          onOk={(success) => {
            setShowModalNovaQuestao(false);
          }}
          onCancel={() => setShowModalNovaQuestao(false)}
        />
      )}
      {!showModalEditarQuestao || (
        <ModalEditarQuestao
          key={"q-" + Math.ceil(Math.random() * 10 ** 10)}
          idQuiz={"3"}
          position={questaoSelecionada.pos}
          questao={questaoSelecionada.questao}
          tipo={tipo}
          login={null}
          onOk={(success) => {
            setShowModalEditarQuestao(false);
          }}
          onCancel={() => setShowModalEditarQuestao(false)}
        />
      )}
    </Container>
  );
}
