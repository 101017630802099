import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { deslogar } from "../../../actions/Login.action";
import { Avatar, Typography, Menu, Divider } from "antd";
import {
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
  FormOutlined,
} from "@ant-design/icons";
import style from "./style.module.css";
import { Link } from "react-router-dom";

const { Title, Text } = Typography;

class User extends Component {
  render() {
    const {
      accessToken,
      usuario: { nome, email },
    } = this.props.login;
    return (
      <div className={style.modalUser}>
        <Avatar size={64} icon={<UserOutlined />} />
        <Title level={4} style={{ marginBottom: 0 }}>
          {nome}
        </Title>
        <Text>{email}</Text>
        <Divider style={{ marginTop: 12, marginBottom: 0 }}></Divider>
        <Menu>
          <Menu.Item>
            <Link to={`/simulados/listar`}>
              <FormOutlined />
              Simulados
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link
              to={{
                pathname: "/usuario",
                state: { foo: "BAR2" },
              }}
            >
              <SettingOutlined />
              Gerenciar
            </Link>
          </Menu.Item>
          <Menu.Item onClick={() => this.props.deslogar(accessToken)}>
            <LogoutOutlined />
            Sair
          </Menu.Item>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  login: state.login,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ deslogar }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(User);
