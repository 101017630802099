import React from 'react'
import style from './style.module.css'
import Logo from '../Logo'
import Menu from './Menubar'
import { Affix, Row, Col } from 'antd'

export default props => {
  const { menu = true, logo = true } = props || []
  return (
    <Affix offsetTop={32}>
      <header className={style.headerBar}>
        <div className="container">
          <Row className={style.headerBarFlex} style={{alignItems: 'center'}}> 
            {
              logo ? <Col span={6}><Logo beta={true} /></Col> : null
            }

            {
              menu ? <Col span={18}><Menu /></Col> : null
            }
          </Row>
        </div>
      </header>
    </Affix>
  )
}