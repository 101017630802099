const INITIAL_STATE = {
  usuario: null,
  loadUsuarios: false,
  salvando: false
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    /** GET */
    case 'GET_USUARIO_REQUEST': {
      return { ...state, loadUsuarios: true, salvando: false }
    }
    case 'GET_USUARIO_SUCCESS': {
      return { ...state, loadUsuarios: false, usuario: action.payload }
    }
    case 'GET_USUARIO_FAILURE': {
      return { ...INITIAL_STATE, loadUsuarios: false }
    }

    /** STORE */
    case 'UPDATE_USUARIO_REQUEST': {
      return { ...state, salvando: true }
    }
    case 'UPDATE_USUARIO_SUCCESS': {
      return { ...state, usuario: action.payload, salvando: false }
    }
    case 'UPDATE_USUARIO_FAILURE': {
      return { ...INITIAL_STATE, salvando: false }
    }

    /** DEFAULT */
    default: {
      return state
    }
  }
}