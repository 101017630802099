import React from "react";
import { Result, Button, message, Typography, Tooltip } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { LinkOutlined } from "@ant-design/icons";
import {
  FacebookShareButton,
  FacebookIcon,
  EmailShareButton,
  EmailIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

import { Container } from "./styles";

const { Text } = Typography;

function Publicado({ quiz }) {
  const { title, hash } = quiz;
  const shareUrl = `https://www.cadedireito.com.br/quiz/${hash}`;
  const size = 40;

  return (
    <Container>
      <Result
        status="success"
        style={{ margin: "96px 0" }}
        title="Quiz pronto!"
        subTitle={
          <>
            <p>
              Copie o link abaixo e compartilhe com seus alunos ou seguidores.
            </p>
            <p>
              <Text code>{shareUrl}</Text>
            </p>
            <p>
              <CopyToClipboard
                text={shareUrl}
                onCopy={() =>
                  message.success("Link para o quiz copiado com sucesso!")
                }
              >
                <Button>
                  <LinkOutlined /> Copiar link
                </Button>
              </CopyToClipboard>
            </p>
            <div>
              {/****** */}
              <span>Compartilhe o seu quiz</span>
              <div className="mb-3">
                <Tooltip title="Compartilhe com o Facebook">
                  <FacebookShareButton
                    url={shareUrl}
                    quote={title}
                    className="sharesButton"
                  >
                    <FacebookIcon
                      size={size}
                      round
                      className="sharesButtonIcon"
                    />
                  </FacebookShareButton>
                </Tooltip>
                <Tooltip title="Compartilhe com o Twitter">
                  <TwitterShareButton
                    url={shareUrl}
                    title={title}
                    className="sharesButton"
                  >
                    <TwitterIcon
                      size={size}
                      round
                      className="sharesButtonIcon"
                    />
                  </TwitterShareButton>
                </Tooltip>
                <Tooltip title="Compartilhe com o Telegram">
                  <TelegramShareButton
                    url={shareUrl}
                    title={title}
                    className="sharesButton"
                  >
                    <TelegramIcon
                      size={size}
                      round
                      className="sharesButtonIcon"
                    />
                  </TelegramShareButton>
                </Tooltip>
                <Tooltip title="Compartilhe com seus contatos do Whatsapp">
                  <WhatsappShareButton
                    url={shareUrl}
                    title={title}
                    separator=" :: "
                    className="sharesButton"
                  >
                    <WhatsappIcon
                      size={size}
                      round
                      className="sharesButtonIcon"
                    />
                  </WhatsappShareButton>
                </Tooltip>
                <Tooltip title="Compartilhe por E-mail">
                  <EmailShareButton
                    url={shareUrl}
                    subject={title}
                    body="body"
                    className="sharesButton"
                  >
                    <EmailIcon size={size} round className="sharesButtonIcon" />
                  </EmailShareButton>
                </Tooltip>
              </div>
              {/****** */}
            </div>
          </>
        }
      />
    </Container>
  );
}

export default Publicado;
