import { API_URL } from "../configs/config";

export const getCargo = (id) => {
  return (dispatch) => {
    dispatch({ type: "GET_CARGO_REQUEST" });
    fetch(`${API_URL}/cargo/${id}`)
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        return dispatch({
          type: "GET_CARGO_SUCCESS",
          payload: response,
        });
      })
      .catch((error) =>
        dispatch({
          type: "GET_CARGO_FAILURE",
          payload: error,
        })
      );
  };
};

export const getCargos = () => {
  return (dispatch) => {
    dispatch({ type: "GET_CARGOS_REQUEST" });
    fetch(`${API_URL}/cargo`)
      .then((T) => {
        return T.json();
      })
      .then((response) => {
        return dispatch({
          type: "GET_CARGOS_SUCCESS",
          payload: response,
        });
      })
      .catch((error) =>
        dispatch({
          type: "GET_CARGOS_FAILURE",
          payload: error,
        })
      );
  };
};
