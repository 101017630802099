import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { useSelector, useDispatch } from "react-redux";

import { Tabs, Modal, Button } from "antd";
import { ExclamationCircleOutlined, EyeOutlined } from "@ant-design/icons";
import { resetQuiz } from "../../../actions/Quiz.action";
import DadosQuiz from "./dados";
import QuestoesQuiz from "./questoes";
import PublicacaoQuiz from "./publicacao";
import Publicado from "./publicado";
import Preview from "../Preview";
import { Container } from "./styles";

const { TabPane } = Tabs;
const { confirm } = Modal;

function NovoQuiz(props) {
  const dispatch = useDispatch();

  const [activeKey, setActiveKey] = useState("#1");
  const [publicado, setPublicado] = useState(false);
  const [quiz, setQuiz] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(false);

  const {
    titulo,
    qtdPorPagina,
    tipo,
    expiracao,
    visibilidade,
    ativo,
    mostrarResultado,
    mostrarGabarito,
    emailsNovos,
    questoes,
  } = useSelector((state) => {
    return state.quiz;
  });

  const { location } = props;

  useEffect(() => {
    if (location.hash) {
      setActiveKey(location.hash);
    }
    if (location.query?.novo) {
      if (location.query?.criandoExists) {
        confirm({
          title: "Existe um quiz sendo criado",
          icon: <ExclamationCircleOutlined />,
          content: "Deseja desfazer e começar um novo?",
          okText: "Sim",
          okType: "danger",
          cancelText: "Continuar criando",
          onOk() {
            dispatch(resetQuiz());
          },
          onCancel() {},
        });
      }
    }
  }, [dispatch, location]);

  function quizPublicado(_quiz) {
    setQuiz(_quiz);
    setPublicado(true);
  }

  function visualizar() {
    setPreviewVisible(true);
  }

  function closePreviewModal() {
    setPreviewVisible(false);
  }

  return (
    <Container className="container">
      <h3 className="mt-4">Criar quiz</h3>
      {!publicado ? (
        <Tabs
          activeKey={activeKey}
          type="card"
          size="large"
          onChange={(value) => setActiveKey(value)}
          tabBarExtraContent={
            <Button type="text" onClick={visualizar}>
              <EyeOutlined /> Visualizar
            </Button>
          }
        >
          <TabPane tab="Dados do quiz" key="#1">
            <DadosQuiz
              titulo={titulo}
              qtdPorPagina={qtdPorPagina}
              tipo={tipo}
              expiracao={expiracao}
            ></DadosQuiz>
          </TabPane>
          <TabPane tab="Questões" key="#2">
            <QuestoesQuiz questoesSelector={questoes}></QuestoesQuiz>
          </TabPane>
          <TabPane tab="Publicação" key="#3">
            <PublicacaoQuiz
              quizPublicado={(_quiz) => {
                quizPublicado(_quiz);
              }}
              titulo={titulo}
              qtdPorPagina={qtdPorPagina}
              tipo={tipo}
              expiracao={expiracao}
              visibilidade={visibilidade}
              ativo={ativo}
              mostrarResultado={mostrarResultado}
              mostrarGabarito={mostrarGabarito}
              emailsNovos={emailsNovos}
              questoes={questoes}
              setActiveKey={setActiveKey}
            ></PublicacaoQuiz>
          </TabPane>
        </Tabs>
      ) : (
        <Publicado quiz={quiz} />
      )}
      <Modal
        title={[
          <EyeOutlined />,
          <span className="ml-2">Pré-visualização do quiz</span>,
        ]}
        visible={previewVisible}
        onOk={() => closePreviewModal()}
        onCancel={() => closePreviewModal()}
        footer={null}
        style={{ top: 10, paddingBottom: 0 }}
        width={"100%"}
        bodyStyle={{
          height: "calc(100vh - 95px)",
          overflow: "scroll",
        }}
      >
        <Preview />
      </Modal>
    </Container>
  );
}

export default withRouter(NovoQuiz);
