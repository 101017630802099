import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import style from "./style.module.css";
import {
  Row,
  Col,
  Space,
  Spin,
  Button,
  Modal,
  Result,
  Pagination,
  Input,
  Form,
  Tooltip,
  message,
  Divider,
} from "antd";
import { withRouter } from "react-router";
import { API_URL } from "../../../configs/config";
import SimuladoQuestao from "./Questao";
import {
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
  FacebookFilled,
  LinkOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useHistory } from "react-router-dom";
import RenderHelmet from "../../../components/RenderHelmet";
import FacebookLogin from "react-facebook-login";
import config from "../../../config.json";
import { useDispatch } from "react-redux";
import { logarComFacebook } from "../../../actions/Login.action";
import { Link } from "react-router-dom";
import {
  //Facebook
  FacebookShareButton,
  FacebookIcon,
  //Email
  EmailShareButton,
  EmailIcon,
  //Twitter
  TwitterShareButton,
  TwitterIcon,
  //Telegram
  TelegramShareButton,
  TelegramIcon,
  //Whatsapp
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

const { confirm } = Modal;

function Simulado({ match }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const { hash } = match.params;
  const login = useSelector((state) => state.login);
  const [simulado, setSimulado] = useState(null);
  const [loadSimulado, setLoadSimulado] = useState(false);
  const [questoes, setQuestoes] = useState({});
  const [finalizado, setFinalizado] = useState(false);
  const [loadPermissao, setLoadPermissao] = useState(false);
  const [permissao, setPermissao] = useState(false);
  const [paginaQuestoes, setPaginaQuestoes] = useState(1);
  const [error, setError] = useState(null);
  const [mostrarDenunciar, setMostrarDenunciar] = useState(false);
  const [denunciado, setDenunciado] = useState(false);
  const [outrosSimulados, setOutrosSimulados] = useState([]);

  const [formIdentificacao] = Form.useForm();
  const [nome, setNome] = useState(null);
  const [outraIdentificacao, setOutraidentificacao] = useState(null);
  const [identificacao, setIdentificacao] = useState(false);

  const [formDenunciar] = Form.useForm();

  useEffect(
    function () {
      async function getSimulado() {
        let headers = new Headers({
          "Content-Type": "application/json",
        });
        const simuladoResponse = await fetch(
          `${API_URL}/simulado/hash/${hash}`,
          {
            method: "get",
            headers,
          }
        );
        const _simuladoResponse = await simuladoResponse.json();
        if (
          _simuladoResponse.error &&
          _simuladoResponse.error === "NOT FOUND"
        ) {
          setError(true);
          setLoadSimulado(true);
          return false;
        }
        const permitido = getPermissao(_simuladoResponse.data, login);
        setPermissao(permitido);
        setLoadPermissao(true);
        setLoadSimulado(true);
        if (_simuladoResponse.data.ativo === "FALSE") {
          history.push("/");
          return false;
        }
        setSimulado(_simuladoResponse.data);

        const _outrosSimuladosResponse = await fetch(
          `${API_URL}/simulados/outros`,
          {
            method: "get",
            headers,
          }
        );
        const outrosSimuladosResponse = await _outrosSimuladosResponse.json();

        var filtered = [];
        outrosSimuladosResponse.data.map((s, key) => {
          if (
            s.id !== _simuladoResponse.data.id &&
            key < 6 &&
            s.quantidadequestoes > 0
          ) {
            filtered.push(s);
          }
          return true;
        });
        setOutrosSimulados(filtered);
      }
      getSimulado();
    },
    [login, hash, history]
  );

  useEffect(
    function () {
      if (login.isAuthenticate) {
        setNome(login.usuario.nome);
        setOutraidentificacao(login.usuario.email);
        setIdentificacao(true);
      }
    },
    [login]
  );

  function getPermissao(data, login) {
    if (data.visibilidade === "pública") {
      return true;
    }
    if (data.visibilidade === "particular" || data.visibilidade === "privada") {
      if (!login || !login.usuario) {
        return false;
      }
      if (login && login.usuario && login.usuario.email === data.dono.email) {
        return true;
      }
    }
    if (data.visibilidade === "privada") {
      var a = data.simulado_usuarios.find(
        (u) => u.email === login.usuario.email
      );
      if (a) {
        return true;
      }
    }
    return false;
  }

  async function onFinalizarSimulado() {
    setFinalizado(true);
    window.scrollTo(0, 0);
    let headers = new Headers({
      Authorization: `Basic ${login.accessToken}`,
      "Content-Type": "application/json",
    });
    const opts = {
      nome,
      outraIdentificacao,
      questoes,
    };
    const body = JSON.stringify(opts);
    const _simuladoResponse = await fetch(
      `${API_URL}/finalizarsimulado/${simulado.id}`,
      {
        method: "post",
        headers,
        body,
      }
    );
    //const simuladoResponse = await _simuladoResponse.json();
    await _simuladoResponse.json();
  }

  async function callbackFacebook(response) {
    await dispatch(logarComFacebook(response.accessToken));
  }

  function finalizarSimulado() {
    confirm({
      title: "Deseja finalizar o quiz?",
      icon: <ExclamationCircleOutlined />,
      content:
        "Após a confirmação, o quiz será finalizado e suas respostas computadas.",
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      onOk() {
        onFinalizarSimulado(true);
      },
      onCancel() {},
    });
  }

  var expirado = false;
  if (simulado) {
    if (!simulado.expire_at) {
      expirado = false;
    } else {
      const current_time = Math.floor(Date.now() / 1000);
      const expire_time = moment(simulado.expire_at).format("X");
      if (expire_time < current_time) {
        expirado = true;
      }
    }
  }

  async function denunciarSimulado(values) {
    let headers = new Headers({
      Authorization: `Basic ${login.accessToken}`,
      "Content-Type": "application/json",
    });
    const opts = {
      denuncia: values.denuncia,
    };
    const body = JSON.stringify(opts);
    await fetch(`${API_URL}/simulado/${simulado.id}/denunciar`, {
      method: "post",
      headers,
      body,
    });
    message.success("Denúncia enviada com sucesso!");
    setDenunciado(true);
  }

  function changePaginaQuestao(e) {
    window.scrollTo(0, 0);
    setPaginaQuestoes(e);
  }

  function submitFormIdentificacao(values) {
    setNome(values.nome);
    setOutraidentificacao(values.outraidentificacao);
    setIdentificacao(true);
  }

  function isFacebookApp() {
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    return (
      ua.indexOf("FBAN") > -1 ||
      ua.indexOf("FBAV") > -1 ||
      ua.indexOf("Instagram") > -1
    );
  }

  function BlocoEnviar() {
    return (
      <>
        <div className={style.classeFinalizar}>
          <Button
            type="primary"
            value="large"
            shape="round"
            block
            onClick={() => finalizarSimulado()}
          >
            <span>FINALIZAR QUIZ</span>
          </Button>
        </div>
        {denunciado || (
          <div style={{ marginBottom: 48 }}>
            <Form form={formDenunciar} onFinish={denunciarSimulado}>
              <div
                className={
                  mostrarDenunciar
                    ? style.mostrarDenunciar
                    : style.ocultarDenunciar
                }
              >
                <span>Informe o motivo da denúncia</span>
                <Form.Item
                  name="denuncia"
                  rules={[
                    {
                      required: true,
                      message: "Informe o motivo da denúncia!",
                    },
                    {
                      min: 5,
                      message: "A denúncia precisa ter no mínimo 5 caracteres",
                    },
                  ]}
                >
                  <Input.TextArea rows={4} />
                </Form.Item>
              </div>
              <div>
                {mostrarDenunciar ? (
                  <Button
                    name="btn-enviar"
                    key="btn-enviar"
                    id="btn-enviar"
                    type="secondary"
                    value="large"
                    shape="round"
                    htmlType="submit"
                    block
                  >
                    <span>ENVIAR DENUNCIA</span>
                  </Button>
                ) : (
                  <Button
                    type="secondary"
                    name="btn-mostrar"
                    key="btn-mostrar"
                    id="btn-mostrar"
                    value="large"
                    shape="round"
                    htmlType="button"
                    block
                    onClick={() => setMostrarDenunciar(true)}
                  >
                    <span>DENUNCIAR</span>
                  </Button>
                )}
              </div>
            </Form>
          </div>
        )}
      </>
    );
  }

  function renderFeedback() {
    const matriz = questoes;
    var matrizCorreta = {};
    var acertos = 0;

    simulado.simulado_questoes.map((questao, key) => {
      return (matrizCorreta[key + 1] = questao.opcaoCorreta);
    });

    if (
      simulado.mostrarresultado === "FALSE" &&
      simulado.mostrargabarito === "FALSE"
    ) {
      return <></>;
    }

    Object.keys(matrizCorreta).map((linha, key) => {
      if (matriz[key]) {
        if (matrizCorreta[key] === matriz[key]) {
          acertos++;
        }
      }
      return true;
    });
    var qtd = Object.keys(matrizCorreta).length;
    var percent = (100 / qtd) * acertos;

    const shareUrl = "https://www.cadedireito.com.br/quiz/" + simulado?.hash;
    const title = "Participe você também deste quiz!";
    const size = 40;

    return (
      <div className={style.feedbackContainer}>
        <div className={style.gabaritoContainer}>
          <Row gutter={[48, 48]}>
            <Col span={24} md={{ span: 12 }}>
              {simulado.mostrarresultado === "TRUE" && (
                <>
                  <p style={{ marginBottom: 0 }}>
                    <strong>Resultado</strong>
                  </p>
                  <p className={style.resultado}>{percent.toFixed(1)}%</p>
                  <p>
                    {acertos} acerto{acertos !== 1 && "s"} em {qtd} quest
                    {qtd !== 1 ? "ões" : "ão"}
                  </p>
                </>
              )}
              {simulado.mostrargabarito === "TRUE" && (
                <>
                  <p>
                    <strong>Gabarito</strong>
                  </p>
                  {Object.keys(matrizCorreta).map((linha, key) => (
                    <Row className={style.gabaritoLinha} key={key}>
                      <Col style={{ width: 80 }} className={style.number}>
                        {linha}
                      </Col>
                      {simulado.tipo === "certo ou errado" ? (
                        <>
                          <Col
                            flex={1}
                            style={
                              matrizCorreta[linha] === 1
                                ? { color: "#52c41a" }
                                : {}
                            }
                            className={
                              matriz[linha] === 1
                                ? style.checked
                                : matrizCorreta[linha] === 1
                                ? style.correta
                                : ""
                            }
                          >
                            Certo
                          </Col>
                          <Col
                            flex={1}
                            style={
                              matrizCorreta[linha] === 2
                                ? { color: "#52c41a" }
                                : {}
                            }
                            className={
                              matriz[linha] === 2
                                ? style.checked
                                : matrizCorreta[linha] === 2
                                ? style.correta
                                : ""
                            }
                          >
                            Errado
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col
                            flex={1}
                            style={
                              matrizCorreta[linha] === 1
                                ? { color: "#52c41a" }
                                : {}
                            }
                            className={
                              matriz[linha] === 1
                                ? style.checked
                                : matrizCorreta[linha] === 1
                                ? style.correta
                                : ""
                            }
                          >
                            A
                          </Col>
                          <Col
                            flex={1}
                            style={
                              matrizCorreta[linha] === 2
                                ? { color: "#52c41a" }
                                : {}
                            }
                            className={
                              matriz[linha] === 2
                                ? style.checked
                                : matrizCorreta[linha] === 2
                                ? style.correta
                                : ""
                            }
                          >
                            B
                          </Col>
                          <Col
                            flex={1}
                            style={
                              matrizCorreta[linha] === 3
                                ? { color: "#52c41a" }
                                : {}
                            }
                            className={
                              matriz[linha] === 3
                                ? style.checked
                                : matrizCorreta[linha] === 3
                                ? style.correta
                                : ""
                            }
                          >
                            C
                          </Col>
                          <Col
                            flex={1}
                            style={
                              matrizCorreta[linha] === 4
                                ? { color: "#52c41a" }
                                : {}
                            }
                            className={
                              matriz[linha] === 4
                                ? style.checked
                                : matrizCorreta[linha] === 4
                                ? style.correta
                                : ""
                            }
                          >
                            D
                          </Col>
                          {simulado.tipo === "múltipla escolha 5" ? (
                            <Col
                              flex={1}
                              style={
                                matrizCorreta[linha] === 5
                                  ? { color: "#52c41a" }
                                  : {}
                              }
                              className={
                                matriz[linha] === 5
                                  ? style.checked
                                  : matrizCorreta[linha] === 5
                                  ? style.correta
                                  : ""
                              }
                            >
                              E
                            </Col>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </Row>
                  ))}
                </>
              )}
            </Col>

            <Col span={24} md={{ span: 12 }}>
              {simulado.visibilidade === "pública" && (
                <div style={{ marginBottom: 48 }}>
                  <p>
                    <strong>Compartilhe este quiz</strong>
                  </p>
                  <p>
                    <Tooltip title="Compartilhe com o Facebook">
                      <FacebookShareButton
                        url={shareUrl}
                        quote={title}
                        className={style.sharesButton}
                      >
                        <FacebookIcon
                          size={size}
                          round
                          className={style.sharesButtonIcon}
                        />
                      </FacebookShareButton>
                    </Tooltip>
                    <Tooltip title="Compartilhe com o Twitter">
                      <TwitterShareButton
                        url={shareUrl}
                        title={title}
                        className={style.sharesButton}
                      >
                        <TwitterIcon
                          size={size}
                          round
                          className={style.sharesButtonIcon}
                        />
                      </TwitterShareButton>
                    </Tooltip>
                    <Tooltip title="Compartilhe com o Telegram">
                      <TelegramShareButton
                        url={shareUrl}
                        title={title}
                        className={style.sharesButton}
                      >
                        <TelegramIcon
                          size={size}
                          round
                          className={style.sharesButtonIcon}
                        />
                      </TelegramShareButton>
                    </Tooltip>
                    <Tooltip title="Compartilhe com seus contatos do Whatsapp">
                      <WhatsappShareButton
                        url={shareUrl}
                        title={title}
                        separator=" :: "
                        className={style.sharesButton}
                      >
                        <WhatsappIcon
                          size={size}
                          round
                          className={style.sharesButtonIcon}
                        />
                      </WhatsappShareButton>
                    </Tooltip>
                    <Tooltip title="Compartilhe por E-mail">
                      <EmailShareButton
                        url={shareUrl}
                        subject={title}
                        body="body"
                        className={style.sharesButton}
                      >
                        <EmailIcon
                          size={size}
                          round
                          className={style.sharesButtonIcon}
                        />
                      </EmailShareButton>
                    </Tooltip>
                  </p>
                </div>
              )}
              <div style={{ marginBottom: 48 }}>
                <p>
                  <strong>Crie você também o seu quiz</strong>
                  <br />E compartilhe com seus alunos ou amigos
                </p>

                <Link to="/quiz/novo">
                  <Button type="primary" htmlType="button">
                    Criar quiz
                  </Button>
                </Link>
              </div>
              <div style={{ marginBottom: 48 }}>
                <p>
                  <strong>Participe de outros quiz</strong>
                </p>
                {outrosSimulados.map((item) => {
                  return (
                    <p className={style.linkOutroSimulado}>
                      <a href={`/quiz/${item.hash}`}>
                        <div>
                          <LinkOutlined /> {item.titulo}
                        </div>
                        <div>
                          <small>
                            {item.quantidadequestoes} quest
                            {item.quantidadequestoes > 1 ? "ões" : "ão"}
                          </small>
                        </div>
                        <div>
                          <small>{item.dono}</small>
                        </div>
                        <div>
                          <small>{moment(item.criacao.date).fromNow()}</small>
                        </div>
                      </a>
                    </p>
                  );
                })}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={"container-fluid " + style.containerFluid}>
        <div className={style.barra}>
          <div className="container">
            {simulado ? (
              <h1 style={{ marginBottom: 0 }}>{simulado.titulo}</h1>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="container body" style={{ backgroundColor: "white" }}>
          <Result status="error" title="Quiz inexistente!" subTitle="" />
        </div>
      </div>
    );
  }

  return (
    <div className={"container-fluid " + style.containerFluid}>
      <div className={style.barra}>
        <div className="container">
          {simulado ? (
            <>
              <RenderHelmet title={`Quiz: ${simulado.titulo}`} />
              <h1 style={{ marginBottom: 0 }}>{simulado.titulo}</h1>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="container body" style={{ backgroundColor: "white" }}>
        {loadSimulado && simulado ? (
          <>
            {!finalizado ? (
              <>
                {!expirado ? (
                  <>
                    {loadPermissao && permissao ? (
                      <>
                        {simulado.simulado_questoes.length > 0 ? (
                          <>
                            {simulado.visibilidade === "pública" &&
                            !identificacao ? (
                              <div className={style.identificaoContainer}>
                                <h3
                                  style={{
                                    color: "var(--primary2)",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                  }}
                                >
                                  Faça o login ou identifique-se para participar
                                  do quiz
                                </h3>
                                <Divider>Fazer o quiz sem se cadastrar</Divider>
                                <Row gutter={[0, 48]}>
                                  <Col span={24}>
                                    <Form
                                      form={formIdentificacao}
                                      onFinish={submitFormIdentificacao}
                                    >
                                      <span>Nome</span>
                                      <Form.Item
                                        name="nome"
                                        initialValue={""}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Informe o nome!",
                                          },
                                        ]}
                                      >
                                        <Input />
                                      </Form.Item>
                                      <span>
                                        Outra identificação (opcional)
                                      </span>{" "}
                                      <Tooltip title="Informe um e-mail ou rede social">
                                        <QuestionCircleOutlined />
                                      </Tooltip>
                                      <Form.Item
                                        name="outraidentificacao"
                                        initialValue={""}
                                        rules={[
                                          {
                                            message:
                                              "Informe um e-mail ou rede social!",
                                          },
                                        ]}
                                      >
                                        <Input />
                                      </Form.Item>
                                      <Button
                                        type="primary"
                                        htmlType="submit"
                                        style={{ width: "100%" }}
                                      >
                                        Identificar-se e começar
                                      </Button>
                                    </Form>
                                  </Col>
                                </Row>

                                <Divider>ou logue com a sua conta</Divider>

                                <Row gutter={[12, 48]}>
                                  <Col span={12}>
                                    <FacebookLogin
                                      isMobile={isFacebookApp()}
                                      disableMobileRedirect={!isFacebookApp()}
                                      autoLoad={isFacebookApp()}
                                      redirectUri={
                                        window.location.origin + "/login"
                                      }
                                      isDisabled={login.loadLogin}
                                      appId={config.FACEBOOK_APP_ID}
                                      fields="name,email,picture"
                                      textButton=" Facebook"
                                      cssClass={
                                        "ant-btn ant-btn-facebook ant-btn-100"
                                      }
                                      icon={<FacebookFilled />}
                                      callback={(callback) =>
                                        callbackFacebook(callback)
                                      }
                                    />
                                  </Col>
                                  <Col span={12}>
                                    <Link
                                      to={{
                                        pathname: "/login",
                                        param: {
                                          origin: window.location.pathname,
                                        },
                                      }}
                                    >
                                      <Button
                                        type="primary"
                                        htmlType="submit"
                                        style={{ width: "100%" }}
                                      >
                                        Login
                                      </Button>
                                    </Link>
                                  </Col>
                                </Row>
                              </div>
                            ) : (
                              <>
                                <Row>
                                  <Col span={24}>
                                    {simulado.simulado_questoes
                                      .slice(
                                        paginaQuestoes *
                                          simulado.questoesporpagina -
                                          simulado.questoesporpagina,
                                        paginaQuestoes *
                                          simulado.questoesporpagina
                                      )
                                      .map((questao, key) => (
                                        <div
                                          key={
                                            paginaQuestoes *
                                              simulado.questoesporpagina -
                                            simulado.questoesporpagina +
                                            key +
                                            1
                                          }
                                        >
                                          {/*<div key={key}>{renderQuestao(questao, key)}</div>*/}
                                          <SimuladoQuestao
                                            questao={questao}
                                            tipo={simulado.tipo}
                                            numero={
                                              paginaQuestoes *
                                                simulado.questoesporpagina -
                                              simulado.questoesporpagina +
                                              key +
                                              1
                                            }
                                            questoes={questoes}
                                            setQuestoes={setQuestoes}
                                          />
                                          {paginaQuestoes *
                                            simulado.questoesporpagina -
                                            simulado.questoesporpagina +
                                            key +
                                            1 ===
                                            simulado.simulado_questoes
                                              .length && <BlocoEnviar />}
                                        </div>
                                      ))}
                                  </Col>
                                </Row>
                                <Row gutter={[24, 24]}>
                                  <Col span={24}>
                                    <div
                                      className="mb-5"
                                      style={{ textAlign: "center" }}
                                    >
                                      <Pagination
                                        simple
                                        pageSize={simulado.questoesporpagina}
                                        defaultCurrent={1}
                                        total={
                                          simulado.simulado_questoes.length
                                        }
                                        onChange={(e) => changePaginaQuestao(e)}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            )}
                          </>
                        ) : (
                          <Result
                            status="error"
                            title="Não há questões cadastradas neste quiz!"
                            subTitle=""
                          />
                        )}
                      </>
                    ) : (
                      <Result
                        status="error"
                        title="Você não tem permissão para acessar este quiz!"
                        subTitle="Efetue o login com uma conta autorizada para poder acessá-lo."
                      />
                    )}
                  </>
                ) : (
                  <div className={style.containerLoading}>
                    <Result
                      status="error"
                      title="Quiz encerrado!"
                      subTitle="Não é possível participar do quiz pois já foi passou da data limite."
                    />
                  </div>
                )}
              </>
            ) : (
              <div className={style.containerLoading}>
                <Result
                  status="success"
                  title="Quiz finalizado com sucesso!"
                  subTitle="Agradecemos a sua participação."
                />
                {renderFeedback()}
              </div>
            )}
          </>
        ) : (
          <div className={style.containerLoading}>
            <Space size="middle">
              <Spin tip="Carregando quiz..." size="large" />
            </Space>
          </div>
        )}
      </div>
    </div>
  );
}

export default withRouter(Simulado);
