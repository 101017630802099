import styled from "styled-components";
import { Link } from "react-router-dom";

export const CadeDireitoLogo = styled(Link)`
  display: inline-block;

  &.white {
    opacity: 0.85;
  }
`;
