import React, { useState, useEffect } from "react";
import { Row, Col, Badge, Collapse, Button, Skeleton, Result } from "antd";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import BarraHistorico from "../../components/BarraHistorico";
import { withRouter } from "react-router";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import RenderHelmet from "../../components/RenderHelmet";
import {
  Container,
  ChartContainer,
  BoxProva,
  Titulo,
  ColMaterias,
  ColChart,
} from "./styles.js";

import ReactGa from "react-ga";
import { API_URL, GA_TRACKING_ID } from "../../configs/config";

const { Panel } = Collapse;

const COLORS = [
  "#f5222d",
  "#fa541c",
  "#fa8c16",
  "#faad14",
  "#fadb14",
  "#a0d911",
  "#52c41a",
  "#13c2c2",
  "#1890ff",
  "#2f54eb",
  "#722ed1",
  "#eb2f96",
  "#a8071a",
  "#ad2102",
  "#ad4e00",
  "#ad6800",
  "#ad8b00",
  "#5b8c00",
  "#237804",
  "#006d75",
  "#0050b3",
  "#10239e",
  "#391085",
  "#9e1068",
];

function ProvaDados({ match }) {
  window.scrollTo(0, 0);
  const { id } = match.params;
  const [loadingProva, setLoadingProva] = useState(true);
  const [prova, setProva] = useState(null);

  useEffect(() => {
    async function loadProva() {
      setLoadingProva(true);
      let headers = new Headers({
        "Content-Type": "application/json",
      });
      const provaResponse = await fetch(`${API_URL}/concurso/${id}`, {
        method: "get",
        headers,
      });
      const _prova = await provaResponse.json();
      setProva(_prova);

      ReactGa.initialize(GA_TRACKING_ID);
      ReactGa.pageview(`/concurso/${id}`);

      setLoadingProva(false);
    }
    loadProva();
  }, [id]);

  if (loadingProva) {
    return (
      <Container className="container">
        <BarraHistorico title="" match={match}></BarraHistorico>
        <Row>
          <Skeleton active paragraph={{ rows: 12 }}></Skeleton>
        </Row>
      </Container>
    );
  }

  if (!prova.questoes) {
    return (
      <Container className="container">
        <BarraHistorico title="" match={match}></BarraHistorico>
        <Row style={{ justifyContent: "center" }}>
          <Result
            title="Concurso não encontrado"
            extra={
              <Link to="/concursos">
                <Button type="primary" key="console">
                  Ir para a página de seleção de concursos
                </Button>
              </Link>
            }
          />
          ,
        </Row>
      </Container>
    );
  }

  var materias = [];
  prova.questoes.map((questao) => {
    const materia = materias.find(
      (materia) => materia.id === questao.assunto.materia.id
    );
    if (materia) {
      const assunto = materia.assuntos.find(
        (assunto) => assunto.id === questao.assunto.id
      );
      if (assunto) {
        assunto.value++;
      } else {
        materia.assuntos.push({ ...questao.assunto, value: 1 });
      }
      materia.value++;
    } else {
      materias.push({
        ...questao.assunto.materia,
        assuntos: [{ ...questao.assunto, value: 1 }],
        name: questao.assunto.materia.nome,
        value: 1,
      });
    }
    return true;
  });

  return (
    <Container className="container">
      <RenderHelmet
        title={`Cade Direito: Prova: ${prova.banca.nome} - ${prova.orgao.nome} - ${prova.nome} - ${prova.ano}`}
        description={`Prova para ${prova.nome}, ${prova.ano} - banca ${prova.banca.nome}, órgão ${prova.orgao.nome}`}
      />
      <BarraHistorico
        title={
          <b>
            {prova.banca.nome} - {prova.orgao.nome} - {prova.nome} - {prova.ano}
          </b>
        }
        match={match}
      />
      <Row style={{ marginBottom: 24 }}>
        <Col sm={24}>
          <BoxProva>
            <Row style={{ alignItems: "center" }}>
              <Col flex="auto">
                <Titulo>
                  {prova.banca.nome} - {prova.orgao.nome} - {prova.nome} -{" "}
                  {prova.ano}
                </Titulo>
              </Col>
              <Col style={{ margin: "auto" }}>
                <Link to={`/concursos/simulado/${id}`}>
                  <Button
                    type="primary"
                    size="large"
                    icon={
                      <FontAwesomeIcon
                        icon={faClipboardList}
                        style={{ marginRight: 8 }}
                      />
                    }
                  >
                    Resolver Prova
                  </Button>
                </Link>{" "}
                {/*<Button
                  type="primary"
                  size="large"
                  icon={
                    <FontAwesomeIcon
                      icon={faPollH}
                      style={{ marginRight: 8 }}
                    />
                  }
                >
                  Gabarito
                </Button>*/}
              </Col>
            </Row>
            <p className="mt-2">
              <b>Total de questões: </b>
              {prova.questoes.length}
            </p>
            <Row>
              <ColMaterias>
                <Collapse>
                  {materias.map((materia, index) => {
                    return (
                      <Panel
                        header={
                          <>
                            <Badge color={COLORS[index % COLORS.length]} />
                            {materia.nome}
                            {" - "}
                            <b>
                              {materia.value} quest
                              {materia.value === 1 ? "ão" : "ões"}
                            </b>
                          </>
                        }
                        key={index}
                      >
                        <ul>
                          {materia.assuntos.map((assunto, index) => {
                            return (
                              <li>
                                {assunto.nome}
                                {" - "}
                                <b>
                                  {assunto.value} quest
                                  {materia.value === 1 ? "ão" : "ões"}
                                </b>
                              </li>
                            );
                          })}
                        </ul>
                      </Panel>
                    );
                  })}
                </Collapse>
              </ColMaterias>
              <ColChart>
                <ChartContainer>
                  <PieChart width={340} height={340}>
                    <Pie
                      data={materias}
                      labelLine={true}
                      label={materias}
                      outerRadius={120}
                      fill="#8884d8"
                    >
                      {materias.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ChartContainer>
              </ColChart>
            </Row>
          </BoxProva>
        </Col>
      </Row>
    </Container>
  );
}

export default withRouter(ProvaDados);
