import React from 'react'
import styles from './style.module.css'
import MenuLogin from './ModalLogin'
import MenuUser from './ModalUser'

const Login = () => (
  <MenuLogin />
)

const User = () => (
  <MenuUser />
)

const Menu = props => {
  return (
    <div className={styles.modal}>
      {props.children }
    </div>
  )
}

Menu.Login = Login
Menu.User = User

export default Menu