import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import style from "../style.module.css";
import { ScissorOutlined } from "@ant-design/icons";

export default function SimuladoQuestao({
  questao,
  numero,
  questoes,
  setQuestoes,
  tipo,
}) {
  const [respostasCortadas, setRespostasCortadas] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [respostaSelecionada, setRespostaSelecionada] = useState(null);
  useEffect(
    function () {
      let qs = Object.keys(questoes).find((q) => {
        return parseInt(q) === parseInt(numero);
      });
      if (qs) {
        setRespostaSelecionada(questoes[qs]);
      }
    },
    [respostaSelecionada, numero, questoes]
  );

  function renderResposta(resposta, i) {
    var classes =
      respostaSelecionada === i + 1
        ? style.respostaSelecionadaIcone
        : style.respostaIcone;
    return (
      <Row
        type="flex"
        align="middle"
        key={i}
        style={{ marginTop: 24, marginBottom: 24 }}
      >
        <Col
          flex="20px"
          className={
            respostasCortadas[i]
              ? style.respostaScissorsTrue
              : style.respostaScissors
          }
        >
          <ScissorOutlined
            onClick={() => {
              var r = respostasCortadas;
              r[i] = !r[i];
              setRespostasCortadas([...r]);
            }}
          />
        </Col>
        <Col flex="50px">
          <div
            onClick={() => {
              var n = i + 1;
              setRespostaSelecionada(n);
              var q = questoes;
              q[numero] = n;
              setQuestoes(q);
              return true;
            }}
            className={classes}
          >
            {tipo !== "certo ou errado" && String.fromCharCode(65 + i)}
          </div>
        </Col>
        <Col
          flex="auto"
          style={{ marginBottom: 0, maxWidth: "calc(100% - 80px)" }}
        >
          <div
            style={{ textAlign: "justify" }}
            className={respostasCortadas[i] ? style.respostaCortada : ""}
          >
            {resposta.texto}
          </div>
        </Col>
      </Row>
    );
  }

  return (
    <div className={style.questao}>
      <div className={style.numero}>Questão {numero}</div>
      <div className={style.enunciado}>
        <div dangerouslySetInnerHTML={{ __html: questao.enunciado }} />
      </div>
      <div className={style.opcoes}>
        {questao.simulado_questao_respostas.map((resposta, key) => {
          if (tipo === "certo ou errado" && key === 0) {
            return (
              <div key={key}>{renderResposta({ texto: "Certo" }, key)}</div>
            );
          }
          if (tipo === "certo ou errado" && key === 1) {
            return (
              <div key={key}>{renderResposta({ texto: "Errado" }, key)}</div>
            );
          }
          if (tipo === "certo ou errado" && key > 1) {
            return <></>;
          }
          if (tipo === "múltipla escolha 4" && key > 3) {
            return <></>;
          }
          return <div key={key}>{renderResposta(resposta, key)}</div>;
        })}
      </div>
    </div>
  );
}
