import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import style from "./style.module.css";
import { Row, Col, Space, Spin, Button, Modal, Result, Pagination } from "antd";
import { withRouter } from "react-router";
import SimuladoQuestao from "./Questao";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import RenderHelmet from "../../../components/RenderHelmet";

const { confirm } = Modal;

function Preview() {
  const [questoes, setQuestoes] = useState({});
  const [finalizado, setFinalizado] = useState(false);
  const [paginaQuestoes, setPaginaQuestoes] = useState(1);

  useEffect(() => {
    setPaginaQuestoes(1);
    setFinalizado(false);
  }, []);

  const simulado2 = useSelector((state) => {
    return state.quiz;
  });
  const simulado = {
    mostrarresultado: simulado2.mostrarResultado ? "TRUE" : "FALSE",
    mostrargabarito: simulado2.mostrarGabarito ? "TRUE" : "FALSE",
    questoesporpagina: parseInt(simulado2.qtdPorPagina, 10),
    simulado_questoes: [],
    tipo: simulado2.tipo,
    titulo: simulado2.titulo,
  };
  simulado2.questoes.map((questao) => {
    const simulado_questao = {
      enunciado: questao.enunciado,
      opcaoCorreta: questao.opcaoCorreta,
      simulado_questao_respostas: [],
    };
    questao.respostas.map((resposta) => {
      return simulado_questao.simulado_questao_respostas.push({
        texto: resposta.texto,
      });
    });
    return simulado.simulado_questoes.push(simulado_questao);
  });

  async function onFinalizarSimulado() {
    setFinalizado(true);
    window.scrollTo(0, 0);
  }

  function finalizarSimulado() {
    confirm({
      title: "Deseja finalizar o simulado?",
      icon: <ExclamationCircleOutlined />,
      content:
        "Após a confirmação, o simulado será finalizado e suas respostas computadas.",
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      onOk() {
        onFinalizarSimulado(true);
      },
      onCancel() {},
    });
  }

  function changePaginaQuestao(e) {
    window.scrollTo(0, 0);
    setPaginaQuestoes(e);
  }

  function BlocoEnviar() {
    return (
      <>
        <div className={style.classeFinalizar}>
          <Button
            type="primary"
            value="large"
            shape="round"
            block
            onClick={() => finalizarSimulado()}
          >
            <span>FINALIZAR SIMULADO</span>
          </Button>
        </div>
      </>
    );
  }

  function renderFeedback() {
    const matriz = questoes;
    var matrizCorreta = {};
    var acertos = 0;

    simulado.simulado_questoes.map((questao, key) => {
      return (matrizCorreta[key + 1] = questao.opcaoCorreta);
    });

    if (
      simulado.mostrarresultado === "FALSE" &&
      simulado.mostrargabarito === "FALSE"
    ) {
      return <></>;
    }

    Object.keys(matrizCorreta).map((linha, key) => {
      if (matriz[key]) {
        if (matrizCorreta[key] === matriz[key]) {
          acertos++;
        }
      }
      return true;
    });
    var qtd = Object.keys(matrizCorreta).length;
    var percent = (100 / qtd) * acertos;

    return (
      <div className={style.feedbackContainer}>
        <div className={style.gabaritoContainer}>
          <Row gutter={[48, 48]}>
            <Col span={24} md={{ span: 12, offset: 6 }}>
              {simulado.mostrarresultado === "TRUE" && (
                <>
                  <p style={{ marginBottom: 0 }}>
                    <strong>Resultado</strong>
                  </p>
                  <p className={style.resultado}>{percent.toFixed(1)}%</p>
                  <p>
                    {acertos} acerto{acertos !== 1 && "s"} em {qtd} quest
                    {qtd !== 1 ? "ões" : "ão"}
                  </p>
                </>
              )}
              {simulado.mostrargabarito === "TRUE" && (
                <>
                  <p>
                    <strong>Gabarito</strong>
                  </p>
                  {Object.keys(matrizCorreta).map((linha, key) => (
                    <Row className={style.gabaritoLinha} key={key}>
                      <Col style={{ width: 80 }} className={style.number}>
                        {linha}
                      </Col>
                      {simulado.tipo === "certo ou errado" ? (
                        <>
                          <Col
                            flex={1}
                            style={
                              matrizCorreta[linha] === 1
                                ? { color: "#52c41a" }
                                : {}
                            }
                            className={
                              matriz[linha] === 1
                                ? style.checked
                                : matrizCorreta[linha] === 1
                                ? style.correta
                                : ""
                            }
                          >
                            Certo
                          </Col>
                          <Col
                            flex={1}
                            style={
                              matrizCorreta[linha] === 2
                                ? { color: "#52c41a" }
                                : {}
                            }
                            className={
                              matriz[linha] === 2
                                ? style.checked
                                : matrizCorreta[linha] === 2
                                ? style.correta
                                : ""
                            }
                          >
                            Errado
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col
                            flex={1}
                            style={
                              matrizCorreta[linha] === 1
                                ? { color: "#52c41a" }
                                : {}
                            }
                            className={
                              matriz[linha] === 1
                                ? style.checked
                                : matrizCorreta[linha] === 1
                                ? style.correta
                                : ""
                            }
                          >
                            A
                          </Col>
                          <Col
                            flex={1}
                            style={
                              matrizCorreta[linha] === 2
                                ? { color: "#52c41a" }
                                : {}
                            }
                            className={
                              matriz[linha] === 2
                                ? style.checked
                                : matrizCorreta[linha] === 2
                                ? style.correta
                                : ""
                            }
                          >
                            B
                          </Col>
                          <Col
                            flex={1}
                            style={
                              matrizCorreta[linha] === 3
                                ? { color: "#52c41a" }
                                : {}
                            }
                            className={
                              matriz[linha] === 3
                                ? style.checked
                                : matrizCorreta[linha] === 3
                                ? style.correta
                                : ""
                            }
                          >
                            C
                          </Col>
                          <Col
                            flex={1}
                            style={
                              matrizCorreta[linha] === 4
                                ? { color: "#52c41a" }
                                : {}
                            }
                            className={
                              matriz[linha] === 4
                                ? style.checked
                                : matrizCorreta[linha] === 4
                                ? style.correta
                                : ""
                            }
                          >
                            D
                          </Col>
                          {simulado.tipo === "múltipla escolha 5" ? (
                            <Col
                              flex={1}
                              style={
                                matrizCorreta[linha] === 5
                                  ? { color: "#52c41a" }
                                  : {}
                              }
                              className={
                                matriz[linha] === 5
                                  ? style.checked
                                  : matrizCorreta[linha] === 5
                                  ? style.correta
                                  : ""
                              }
                            >
                              E
                            </Col>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </Row>
                  ))}
                </>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  return (
    <div className={"container-fluid " + style.containerFluid}>
      <div className={style.barra}>
        <div className="container">
          {simulado ? (
            <>
              <RenderHelmet title={`Simulado: ${simulado.titulo}`} />
              <h1 style={{ marginBottom: 0 }}>{simulado.titulo}</h1>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="container body" style={{ backgroundColor: "white" }}>
        {simulado ? (
          <>
            {!finalizado ? (
              <>
                {simulado.simulado_questoes.length > 0 ? (
                  <>
                    <Row>
                      <Col span={24}>
                        {simulado.simulado_questoes
                          .slice(
                            paginaQuestoes * simulado.questoesporpagina -
                              simulado.questoesporpagina,
                            paginaQuestoes * simulado.questoesporpagina
                          )
                          .map((questao, key) => (
                            <div
                              key={
                                paginaQuestoes * simulado.questoesporpagina -
                                simulado.questoesporpagina +
                                key +
                                1
                              }
                            >
                              {/*<div key={key}>{renderQuestao(questao, key)}</div>*/}
                              <SimuladoQuestao
                                questao={questao}
                                tipo={simulado.tipo}
                                numero={
                                  paginaQuestoes * simulado.questoesporpagina -
                                  simulado.questoesporpagina +
                                  key +
                                  1
                                }
                                questoes={questoes}
                                setQuestoes={setQuestoes}
                              />
                              {paginaQuestoes * simulado.questoesporpagina -
                                simulado.questoesporpagina +
                                key +
                                1 ===
                                simulado.simulado_questoes.length && (
                                <BlocoEnviar />
                              )}
                            </div>
                          ))}
                      </Col>
                    </Row>
                    <Row gutter={[24, 24]}>
                      <Col span={24}>
                        <div className="mb-5" style={{ textAlign: "center" }}>
                          <Pagination
                            simple
                            pageSize={simulado.questoesporpagina}
                            current={paginaQuestoes}
                            total={simulado.simulado_questoes.length}
                            onChange={(e) => changePaginaQuestao(e)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Result
                    status="error"
                    title="Não há questões cadastradas neste simulado!"
                    subTitle=""
                  />
                )}
              </>
            ) : (
              <div className={style.containerLoading}>
                <Result
                  status="success"
                  title="Simulado finalizado com sucesso!"
                  subTitle="Agradecemos a sua participação."
                />
                {renderFeedback()}
              </div>
            )}
          </>
        ) : (
          <div className={style.containerLoading}>
            <Space size="middle">
              <Spin tip="Carregando simulado..." size="large" />
            </Space>
          </div>
        )}
      </div>
    </div>
  );
}

export default withRouter(Preview);
