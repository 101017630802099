const INITIAL_STATE = {
  concurso: null,
  concursos: null,
  anos: null,
  loadConcursos: false,
  loadAnos: false,
  count: null,
  curPage: null,
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    /** GET */
    case 'GET_CONCURSO_REQUEST':
    case 'GET_CONCURSOS_REQUEST': {
      return { ...state, loadConcursos: true }
    }
    case 'GET_CONCURSO_SUCCESS': {
      return { ...state, loadConcursos: false, concurso: action.payload }
    }
    case 'GET_CONCURSOS_SUCCESS': {
      return { ...state, loadConcursos: false, concursos: action.payload.data, count: action.payload.count, curPage: action.payload.curPage }
    }
    case 'GET_CONCURSO_FAILURE':
    case 'GET_CONCURSOS_FAILURE': {
      return { ...INITIAL_STATE, loadConcursos: false }
    }
    case 'GET_ANOS_REQUEST': {
      return { ...state, loadAnos: true }
    }
    case 'GET_ANOS_SUCCESS': {
      return { ...state, loadAnos: false, anos: action.payload }
    }
    case 'GET_ANOS_FAILURE': {
      return { ...INITIAL_STATE, loadAnos: false }
    }  

    /** STORE */
    case 'STORE_CONCURSO_REQUEST': {
      return { ...state, loadConcursos: true }
    }
    case 'STORE_CONCURSO_SUCCESS': {
      return { ...state, loadConcursos: false }
    }
    case 'STORE_CONCURSO_FAILURE': {
      return { ...state, loadConcursos: false }
    }

    /** DEFAULT */
    default: {
      return state
    }
  }
}