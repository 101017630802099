import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Form, Button, Divider, Input } from "antd";
import FacebookLogin from "react-facebook-login";
import { UserOutlined, LockOutlined, FacebookFilled } from "@ant-design/icons";
import config from "../../config.json";
import style from "./style.module.css";
import { Link } from "react-router-dom";
import mail001 from "../../images/mail001.jpg";
import { useHistory, useLocation } from "react-router-dom";
import RenderHelmet from "../../components/RenderHelmet";

import { useDispatch } from "react-redux";

import { logar, logarComFacebook } from "../../actions/Login.action";

import { addPersistOrigin } from "../../actions/PersistOrigin.action";

import ReactGa from "react-ga";
import { GA_TRACKING_ID } from "../../configs/config";

function FullLogin(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const formRef = useRef();

  const persistOrigin = useSelector((state) => state.persistOrigin.origin);

  const { isAuthenticate, loadLogin, isFailure } = useSelector(
    (state) => state.login
  );

  useEffect(() => {
    ReactGa.initialize(GA_TRACKING_ID);
    ReactGa.pageview("/login");
    if (location.param?.origin) {
      dispatch(addPersistOrigin(location.param.origin));
    }
    if (location.state?.origin) {
      dispatch(addPersistOrigin(location.state.origin));
    }
  }, [dispatch, location]);

  useEffect(() => {
    if (isAuthenticate) {
      if (persistOrigin) {
        history.push(persistOrigin);
      } else {
        history.push("/");
      }
    }
  }, [isAuthenticate, history, persistOrigin]);

  async function onFinish(values) {
    await dispatch(logar(values.usuario, values.senha));
  }

  function onFinishFailed(errorInfo) {
    // console.log("Failed:", errorInfo);
  }

  async function callbackFacebook(response) {
    await dispatch(logarComFacebook(response.accessToken));
    //window.location.reload();
  }

  function isFacebookApp() {
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    return (
      ua.indexOf("FBAN") > -1 ||
      ua.indexOf("FBAV") > -1 ||
      ua.indexOf("Instagram") > -1
    );
  }

  return (
    <div style={{ margin: "48px auto" }} className="container">
      <RenderHelmet title={"Cade Direito: Login"} />
      <Row>
        <Col sm={{ span: 24 }} md={{ span: 12 }}>
          <div style={{ textAlign: "center", padding: "8px 48px" }}>
            <h1 style={{ color: "var(--primary)" }}>Crie a sua conta</h1>
            <div className={style.textoLateral} style={{ margin: "24px auto" }}>
              <p
                style={{
                  fontSize: "1.2em",
                  color: "#809fbc",
                  marginBottom: 32,
                }}
              >
                É grátis e vai levar apenas alguns instantes.
              </p>
              <Link to={"/registrar"}>
                <Button type="primary" size="large">
                  Crie sua conta
                </Button>
              </Link>
            </div>
            <img alt="e-mail" style={{ width: "100%" }} src={mail001}></img>
          </div>
        </Col>
        <Col
          sm={{ span: 24 }}
          md={{ span: 12 }}
          style={{
            textAlign: "center",
          }}
          className={style.formContainer}
        >
          <h1 style={{ color: "var(--primary)" }}>Faça o login</h1>
          <Form
            name="basic"
            className={style.formLogin}
            ref={formRef}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={() => onFinishFailed()}
          >
            <Form.Item
              name="usuario"
              rules={[{ required: true, message: "Informe o usuário!" }]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Usuário"
              />
            </Form.Item>

            <Form.Item
              name="senha"
              rules={[{ required: true, message: "Informe a senha!" }]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Senha"
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                style={{ width: "100%" }}
                htmlType="submit"
                className={style.buttonLogin}
                size="large"
                disabled={loadLogin}
              >
                Entrar
              </Button>
            </Form.Item>

            {isFailure ? (
              <div>
                <p className={style.loginFailure}>Login ou senha inválido(s)</p>
              </div>
            ) : (
              []
            )}

            {/*<Form.Item name="remember" valuePropName="checked" style={{marginBottom: 0}}>
          <Checkbox>Lembre-me</Checkbox>
          </Form.Item>*/}

            <div style={{ textAlign: "center" }}>
              <Link to={`/recuperar`}>Esqueci minha senha</Link>
            </div>

            <Divider orientation="center">Ou entre com</Divider>

            <Form.Item>
              <Row gutter={8}>
                <Col offset={6} span={12}>
                  <FacebookLogin
                    isDisabled={loadLogin}
                    isMobile={isFacebookApp()}
                    disableMobileRedirect={!isFacebookApp()}
                    autoLoad={isFacebookApp()}
                    redirectUri={window.location.origin + "/login"}
                    appId={config.FACEBOOK_APP_ID}
                    fields="name,email,picture"
                    textButton=" Facebook"
                    cssClass={"ant-btn ant-btn-facebook ant-btn-lg ant-btn-100"}
                    style={{ width: "100%" }}
                    icon={<FacebookFilled />}
                    callback={(callback) => callbackFacebook(callback)}
                  />
                </Col>
              </Row>
              {/*<Button type="google" icon={<GoogleSquareFilled />} style={{marginLeft: 10}} disabled={loadLogin}>Google</Button>*/}
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default FullLogin;
