import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Principal from "./views/Principal";
import Registrar from "./views/Registrar";
import Privacidade from "./views/Privacidade";
import Ativar from "./views/Ativar";
import Disciplinas from "./views/Disciplinas";
import Orgaos from "./views/Orgaos";
import Bancas from "./views/Bancas";
import Concursos from "./views/Concursos";
import Cargos from "./views/Cargos";
import Questoes from "./views/Questoes";
import QuestaoID from "./views/QuestaoID";
import Usuario from "./views/Usuario";
import Contato from "./views/Contato";
import Recuperar from "./views/RecuperarSenha";
import NovaSenha from "./views/NovaSenha";
import NovoQuiz from "./views/Quiz/Novo";
import EditarQuiz from "./views/Quiz/Editar";
import MeusQuiz from "./views/Quiz/Listar";
import FullLogin from "./views/Login";

import CookieBar from "./components/CookieBar";
import SupportBar from "./components/Supportbar";
import HeaderBar from "./components/Headerbar";
import BannerBar from "./components/Bannerbar";
import Footer from "./components/Footer";

import ProvaDados from "./views/Prova";
import Prova from "./views/Prova/prova.js";

import Quiz from "./views/Quiz/Quiz";

import Grupodeestudos from "./views/Grupodeestudos";
import LegislacaoFederal from "./views/Legislacao/Federal";
import LegislacaoFederalText from "./views/Legislacao/Federal/legislacaoFederalText";
import LegislacaoFederalSearch from "./views/Legislacao/Federal/legislacaoFederalSearch";

export default () => {
  return (
    <Router>
      <Switch>
        <Route path="/quiz/listar">
          <SupportBar />
          <HeaderBar />
          <MeusQuiz props={this} />
          <CookieBar />
        </Route>

        <Route path="/quiz/novo">
          <SupportBar />
          <HeaderBar />
          <NovoQuiz />
          <CookieBar />
        </Route>

        <Route path="/quiz/editar/:id">
          <SupportBar />
          <HeaderBar />
          <EditarQuiz />
          <CookieBar />
        </Route>

        <Route path="/quiz/:hash">
          <SupportBar />
          <HeaderBar />
          <Quiz props={this} />
          <CookieBar />
        </Route>

        <Route path="/login">
          <SupportBar />
          <HeaderBar />
          <FullLogin props={this} />
          <Footer />
          <CookieBar />
        </Route>

        <Route path="/registrar">
          <SupportBar />
          <HeaderBar />
          <Registrar />
          <Footer />
          <CookieBar />
        </Route>

        <Route path="/ativar">
          <SupportBar />
          <HeaderBar menu={false} />
          <Ativar props={this} />
          <Footer />
          <CookieBar />
        </Route>

        <Route path="/privacidade">
          <SupportBar />
          <HeaderBar menu={false} />
          <Privacidade />
          <Footer />
        </Route>

        <Route path="/contato">
          <SupportBar />
          <HeaderBar menu={false} />
          <Contato />
          <Footer />
          <CookieBar />
        </Route>

        <Route path="/recuperar">
          <SupportBar />
          <HeaderBar menu={false} />
          <Recuperar />
          <Footer />
          <CookieBar />
        </Route>

        <Route path="/novasenha">
          <SupportBar />
          <HeaderBar menu={false} />
          <NovaSenha />
          <Footer />
          <CookieBar />
        </Route>

        <Route path="/grupo-de-estudos">
          <SupportBar />
          <HeaderBar />
          <Grupodeestudos />
          <Footer />
          <CookieBar />
        </Route>

        <Route path="/legislacao/federal/pesquisar">
          <SupportBar />
          <HeaderBar />
          <LegislacaoFederalSearch />
          <Footer />
          <CookieBar />
        </Route>

        <Route path="/legislacao/federal/:url">
          <SupportBar />
          <HeaderBar />
          <LegislacaoFederalText />
          <Footer />
          <CookieBar />
        </Route>

        <Route path="/legislacao/federal">
          <SupportBar />
          <HeaderBar />
          <LegislacaoFederal />
          <Footer />
          <CookieBar />
        </Route>

        <Route path="/">
          <SupportBar />
          <HeaderBar />
          <Switch>
            <Route path="/questoes/:id">
              <BannerBar />
              <QuestaoID props={this} />
            </Route>

            <Route path="/questoes">
              <BannerBar />
              <Questoes />
            </Route>

            <Route path="/cargos">
              <BannerBar />
              <Cargos />
            </Route>

            <Route path="/disciplinas">
              <BannerBar />
              <Disciplinas />
            </Route>

            <Route path="/orgaos">
              <BannerBar />
              <Orgaos />
            </Route>

            <Route path="/bancas">
              <BannerBar />
              <Bancas />
            </Route>

            <Route path="/concursos/simulado/:id">
              <BannerBar />
              <Prova props={this} />
            </Route>

            <Route path="/concursos/:id">
              <BannerBar />
              <ProvaDados props={this} />
            </Route>

            <Route path="/concursos">
              <BannerBar />
              <Concursos />
            </Route>

            <Route path="/usuario">
              <Usuario />
            </Route>

            <Route path="/">
              <Principal />
            </Route>

            <Redirect to="/" />
          </Switch>
          <Footer />
          <CookieBar />
        </Route>
      </Switch>
    </Router>
  );
};
