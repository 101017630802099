import React from "react";
import { Row, Col, Form, Input, Button } from "antd";

import { Container, ButtonSubmit } from "./styles";

import { API_URL } from "../../../../configs/config";

export default function BarraPesquisa({
  loading,
  setLoading,
  error,
  setError,
  setResultado,
}) {
  async function formSubmit(values) {
    try {
      setError(false);
      setLoading(true);

      const opts = { text: values.texto };

      let headers = new Headers({
        "Content-Type": "application/json",
      });
      const body = JSON.stringify(opts);
      const _ = await fetch(`${API_URL}/legislacao/federal/search`, {
        method: "post",
        headers,
        body,
      });

      const response = await _.json();
      console.log(response);
      setResultado(response.data);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <div className="container mt-5 mb-5">
        <Form onFinish={formSubmit}>
          <Row gutter={24}>
            <Col flex="auto">
              <span>Texto</span>
              <Form.Item name="texto">
                <Input disabled={loading ? true : false} />
              </Form.Item>
            </Col>
            <ButtonSubmit flex="none">
              <Button
                type="primary"
                htmlType="submit"
                disabled={loading ? true : false}
              >
                Pesquisar
              </Button>
            </ButtonSubmit>
          </Row>
        </Form>
      </div>
    </Container>
  );
}
